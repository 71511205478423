import React, {Component} from 'react';
import {withStyles, Fab, Icon} from '@material-ui/core';
import {FusePageSimple, FuseAnimate} from '@fuse';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import DealersList from './DealersList';
import DealersHeader from './DealersHeader';
import DealerDialog from './dialogs/DealerDialog';
import * as Actions from './store/actions';
import reducer from './store/reducers';

const styles = theme => ({
    addButton: {
        position: 'absolute',
        right   : 12,
        top  : 145,
        zIndex  : 99
    }
});

class DealersApp extends Component {

    componentDidMount()
    {
        this.props.getDealers();
    }

    componentDidUpdate(prevProps, prevState)
    {
        if ( !_.isEqual(this.props.location, prevProps.location) )
        {
            this.props.getDealers();
        }
    }

    render()
    {
        const {classes, openNewDealerDialog} = this.props;

        return (
            <React.Fragment>
                <FusePageSimple
                    classes={{
                        content: "p-16 sm:p-24 pb-80",
                        header            : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <DealersHeader pageLayout={() => this.pageLayout}/>
                    }
                    content={
                        <DealersList/>
                    }
                    sidebarInner
                    onRef={instance => {
                        this.pageLayout = instance;
                    }}
                    innerScroll
                />
                <FuseAnimate animation="transition.expandIn" delay={300}>
                    <Fab
                        color="primary"
                        aria-label="add"
                        className={classes.addButton}
                        onClick={openNewDealerDialog}
                    >
                        <Icon>person_add</Icon>
                    </Fab>
                </FuseAnimate>
                <DealerDialog/>
            </React.Fragment>
        )
    };
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getDealers         : Actions.getDealers,
        openNewDealerDialog: Actions.openNewDealerDialog
    }, dispatch);
}

function mapStateToProps({dealersApp})
{
    return {
        dealers          : dealersApp.dealers.entities,
        selectedContactIds: dealersApp.dealers.selectedDealerIds,
        searchText        : dealersApp.dealers.searchText,
    }
}

export default withReducer('dealersApp', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(DealersApp))));
