import axios from 'axios';
import { showMessage } from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { uploadLoading } from 'app/store/actions/fuse';

export const GET_LAYER = '[LAYER] GET LAYER';
export function getLayer() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/layer/read/layer`)
            .then(response => {
                const { layer } = response.data;
                dispatch({
                    type: GET_LAYER,
                    layer: layer,
                })
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load LAYER data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
            });
    })
}

export const ADD_LAYER = '[LAYER] ADD LAYER';
export function addLayer(data) {
    var newData = {
        name: data.name,
        thumbnail: data.thumbnail[0].name,
    }
    const formData = new FormData();
    formData.append("file", data.thumbnail[0].originFileObj);

    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/layer/insert/layer`, {
            ...newData
        })
            .then(res => {
                dispatch(uploadLoading(true));
                if (process.env.REACT_APP_MODE === "production") {
                    uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/layer/${res.data.layer.guid + '_' + res.data.layer.id}/`).then(thumbnail => {
                        const uploadData = [
                            {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`,
                            {
                                id: res.data.layer.id,
                                tableName: "layer",
                                data: uploadData
                            }
                        )
                            .then(response => {
                                const { success } = response.data;
                                dispatch(uploadLoading(false));
                                if (success === true) {
                                    dispatch(showMessage({
                                        message: 'Successfully Inserted',
                                        autoHideDuration: 2000,
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }, variant: 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: ADD_LAYER
                                        })
                                    ]).then(() => dispatch(getLayer()))
                                } else {
                                    console.log(success);
                                }
                            })
                    })
                        .catch(err => {
                            console.log("failed to upload layer thumbnail. ", err);
                            return;
                        })
                } else {
                    formData.append("guid", res.data.layer.guid);
                    formData.append("id", res.data.layer.id);
                    axios.post(`${API_SERVER_URL}/api/layer/upload`, formData)
                        .then(response => {
                            const { success } = response.data;
                            dispatch(uploadLoading(false));
                            if (success === true) {
                                axios.post(`${API_SERVER_URL}/uploads/clear`)
                                    .then(res => {
                                        if (res.data.success === true) {
                                            dispatch(showMessage({
                                                message: 'Successfully Inserted',
                                                autoHideDuration: 2000,
                                                anchorOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }, variant: 'success'
                                            }));
                                            Promise.all([
                                                dispatch({
                                                    type: ADD_LAYER
                                                })
                                            ]).then(() => dispatch(getLayer()))
                                        }
                                    })
                            }
                        }).catch(err => {
                            dispatch(showMessage({
                                message: 'Failed to add LAYER data',
                                autoHideDuration: 2000,
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }, variant: 'error'
                            }));

                            console.log("err", err);
                        });
                }
            })
            .catch(err => {
                dispatch(showMessage({
                    message: 'Failed to upload data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));

                console.log("err", err);
            });
    })
}

export const DELETE_LAYER = '[LAYER] DELETE LAYER';
export function deleteLayer(record) {
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/layer/remove/layer`, {
            id: record.id
        })
            .then(response => {
                const { success } = response.data;
                if (success === true) {
                    var p1 = new Promise((resolve, reject) => {
                        dispatch(uploadLoading(true));
                        if (process.env.REACT_APP_MODE === "production") {
                            const data = [];
                            data.push(record.thumbnail);
                            deleteFiles(data).then(res => {
                                resolve("success");
                            })
                                .catch(err => {
                                    console.log(err);
                                    reject(err);
                                })
                        } else {
                            resolve("success");
                        }
                    })
                    p1.then(p1Value => {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message: 'Successfully Deleted',
                            autoHideDuration: 2000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }, variant: 'success'
                        }));

                        Promise.all([
                            dispatch({
                                type: DELETE_LAYER
                            })
                        ]).then(() => dispatch(getLayer()))
                    }).catch(err => {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message: 'AWS S3 DELETE LAYER ERROR',
                            autoHideDuration: 4000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                        return;
                    })
                } else {
                    dispatch(showMessage({
                        message: 'Some Door Colors are using this style',
                        autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'warning'
                    }));
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message: 'Failed to delete LAYER data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
                console.log("err", err);
            });


    })
}

export const UPDATE_LAYER = '[LAYER] UPDATE LAYER';
export function updateLayer(data, id, guid) {
    var updateData = {
        id: id,
        name: data.name,
        thumbnail: jsonArray([data.thumbnail[0]]),
    }

    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/layer/update/layer`, {
            ...updateData
        }).then(res => {
            dispatch(uploadLoading(true));
            if (process.env.REACT_APP_MODE === "production") {
                putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/layer/${guid + '_' + id}/`).then(thumbnail => {
                    const uploadData = [
                        {
                            fieldName: 'thumbnail',
                            type: 'single',
                            data: thumbnail
                        }
                    ];

                    axios.post(`${API_SERVER_URL}/api/base/updateUploadData`,
                        {
                            id: id,
                            tableName: "layer",
                            data: uploadData
                        }
                    )
                        .then(response => {
                            const { success } = response.data;
                            dispatch(uploadLoading(false));
                            if (success === true) {
                                dispatch(showMessage({
                                    message: 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }, variant: 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: UPDATE_LAYER
                                    })
                                ]).then(() => dispatch(getLayer()))
                            } else {
                                console.log(success);
                            }
                        })
                })
                    .catch(err => {
                        console.log("failed to put type thumbnail. ", err);
                        return;
                    })
            } else {
                if (data.thumbnail[0].originFileObj !== undefined) {
                    const formData = new FormData();
                    formData.append("file", data.thumbnail[0].originFileObj);
                    formData.append("guid", guid);
                    formData.append("id", id);
                    axios.post(`${API_SERVER_URL}/api/layer/upload`, formData)
                        .then(response => {
                            const { success } = response.data;
                            dispatch(uploadLoading(false));
                            if (success === true) {
                                axios.post(`${API_SERVER_URL}/uploads/clear`)
                                    .then(res => {
                                        if (res.data.success === true) {
                                            dispatch(showMessage({
                                                message: 'Successfully Updated',
                                                autoHideDuration: 2000,
                                                anchorOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }, variant: 'success'
                                            }));
                                            Promise.all([
                                                dispatch({
                                                    type: UPDATE_LAYER
                                                })
                                            ]).then(() => dispatch(getLayer()))
                                        }
                                    })
                            }
                        })
                }
                else {
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message: 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }, variant: 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: UPDATE_LAYER
                        })
                    ]).then(() => dispatch(getLayer()))
                }
            }
        }).catch(err => {
            dispatch(showMessage({
                message: 'Failed to update LAYER data',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }, variant: 'error'
            }));

            console.log("err", err);
        });
    })
}

export const SET_LAYER_SORT_ORDER = '[LAYER] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'layer'
        })
            .then(response => {
                const { success } = response.data;
                if (success === true) {
                    dispatch(uploadLoading(false));
                    Promise.all([
                        dispatch({
                            type: SET_LAYER_SORT_ORDER,
                        })
                    ]).then(() => dispatch(getLayer()))
                } else {
                    dispatch(uploadLoading(false));
                }
            })
            .catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message: 'Failed to set sort order',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
                console.log("failed to set sort order in door style.", err);
            })
    })
}