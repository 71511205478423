import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip} from 'antd';
import { DragDropContext, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

let dragingIndex = -1;
class BodyRow extends React.Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
        const style = { ...restProps.style, cursor: 'move' };
        let { className } = restProps;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }

        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />),
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props["data-row-key"];
        return {
            index: props["data-row-key"],
        };
    },
};
const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props["data-row-key"];
        if (dragIndex === hoverIndex) {
            return;
        }    
        props.moveRow(dragIndex, hoverIndex);
        monitor.getItem().index = hoverIndex;
    },
};
const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    }))(
        DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
);

const { Title } = Typography;
const {Option} = Select;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Shapes extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            type_category_id: undefined,
            shapes_name : '',
            catalog_id: undefined,
            thumbnail        : [],
            visible         : false,
            type            : "new",
            id              : null,
        };
        props.getShapes();
        props.getTypeCategory();
        props.getCatalogData();
    }

    components = {
        body: {
          row: DragableBodyRow,
        },
      };

    moveRow = (dragIndex, hoverIndex) => {
        const {shapes} = this.props;
        dragIndex = shapes.findIndex( el => el.id === dragIndex);
        hoverIndex = shapes.findIndex( el => el.id === hoverIndex);
        console.log(dragIndex, hoverIndex);
        if (hoverIndex < dragIndex){
            this.props.setSortOrder({id: shapes[dragIndex].id, sort_order: hoverIndex});        
            for (let rowIndex = hoverIndex; rowIndex < dragIndex; rowIndex++) {                
                this.props.setSortOrder({id: shapes[rowIndex].id, sort_order: rowIndex + 1}); 
            }
        }else{
            this.props.setSortOrder({id: shapes[dragIndex].id, sort_order: hoverIndex});
            for (let rowIndex = dragIndex+1; rowIndex < hoverIndex+1; rowIndex++) {                
                this.props.setSortOrder({id: shapes[rowIndex].id, sort_order: rowIndex - 1});
            }
        }            
    };

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                type_category_id: undefined,
                catalog_id: undefined,
                shapes_name : '',
                type            : type,
                id              : null,
                thumbnail       : [],
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                guid        : record.guid,
                type_category_id: record.shapes_name ? record.type_category_id : "",
                shapes_name : record.shapes_name,
                catalog_id: record.catalog_id,
                type            : type,
                thumbnail: [{
                    uid     : 1,
                    name    : record.thumbnail.split('/').pop(),
                    status  : 'done',
                    url     : record.thumbnail
                }],
            })
        }
        this.setState({
          visible: open,
        });
    };

    // canBeSubmitted()
    // {
    //     const {name, thumbnail, type} = this.state;
    //     return type==="new" ? (name.length > 0 && thumbnail.length > 0) : (name.length > 0);
    // }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              this.setState({visible: false});
                if(type === "new") {
                    this.props.addShapes(values);
                }
                if(type === "edit" && id !== null) {
                    this.props.updateShapes(values, id, this.state.guid);
                }
                
            }
            
          });
    }

    handleDeleteEvent = (record) => {
            const {deleteShapes} = this.props;
            confirm({
                title: 'Do you want to delete this Item?',
                onOk() {
                    deleteShapes(record)
                },
                onCancel() {},
            });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {shapes} = this.props;
        const index = shapes.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && shapes[index-1].type_category_id === record.type_category_id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== shapes.length - 1 && shapes[index+1].type_category_id === record.type_category_id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: shapes[index].id, sort_order: shapes[swapIndex].sort_order});
            this.props.setSortOrder({id: shapes[swapIndex].id, sort_order: shapes[index].sort_order});
        }
    }


    render()
    {
        const { classes, shapes, typeCategory, isLoading, catalog} = this.props;
        const { thumbnail, visible, type, catalog_id } = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;        
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );
        const catalogItems = [];
        catalog.forEach(fElement => {
            catalogItems.push({
                text: fElement.name,
                value: fElement.name
            });
        });
        catalogItems.push({
            text: 'null',
            value: ' '
        })

        const catalogOptions = [];
        catalog.forEach(fElement => {
            catalogOptions.push(<Option key={fElement.id} value={fElement.id}>{ fElement.name }</Option>);
        })

        let typeItems = [];
        let filterByCatalog = typeCategory.filter((item) => item.catalog_id == catalog_id)
        filterByCatalog.length != 0 && filterByCatalog.forEach(fElement => {
            typeItems.push(<Option key={fElement.id} value={fElement.id}>{ fElement.name }</Option>);
        })

        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'shapes_name',
                width: 200,
            },
            {
                key: 'typeCategory',
                title: 'Type Category',
                dataIndex: 'type_category_name',
                width: 200,
            },
            {
                key: 'catalog_name',
                title: 'Catalog',
                dataIndex: 'catalog_name',
                width: 150,
                filters: catalogItems,
                onFilter: (value, record) => record.catalog_name == value,
            },
            {
                key: 'thumbnail',
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 2,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Shape"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            type_category_id  : this.state.type_category_id,
                                            shapes_name     : this.state.shapes_name,
                                            thumbnail           : this.state.thumbnail,
                                            catalog_id    : this.state.catalog_id
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Shape
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="shapes_name"
                                                        {...formItemLayout}
                                                        label="Shape Name"
                                                    >
                                                        {getFieldDecorator("shapes_name", {
                                                            rules: [ {
                                                                required: true, message: 'Enter Shape.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Shape Name"
                                                                onChange={(e)=> {
                                                                    this.setState({shapes_name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="catalog_id"
                                                        {...formItemLayout}
                                                        label="Catalog Name"
                                                    >
                                                        {getFieldDecorator("catalog_id", {
                                                            validateTrigger: 'onSelect',
                                                            rules: [{ required: true, message: 'Please select your Catalog!' }],
                                                        })(
                                                            (<Select
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select you catalog"
                                                                treeDefaultExpandAll
                                                                onSelect={(value) => {
                                                                    this.setState({ catalog_id: value });
                                                                    let data = typeCategory.filter((item) => item.catalog_id == value);
                                                                    setFieldsValue({ type_category_id: data.length != 0 && data[0].id });
                                                                }}
                                                            >
                                                                {catalogOptions}
                                                            </Select>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="type_category_id"
                                                        {...formItemLayout}
                                                        label="Type Name"
                                                    >
                                                        {getFieldDecorator("type_category_id", {
                                                            validateTrigger: 'onSelect',
                                                            rules: [{ required: true, message: 'Please select your Type!' }],
                                                        })(
                                                            (<Select
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select you type"
                                                                treeDefaultExpandAll
                                                                onSelect={(value) => { this.setState({ type_category_id: value}); }}
                                                            >
                                                                {typeItems}
                                                            </Select>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnail"
                                                        {...formItemLayout}
                                                        label="Thumbnail"
                                                    >
                                                        {getFieldDecorator("thumbnail", {
                                                            initialValue: thumbnail,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please select Thumbnail!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        thumbnail: [],
                                                                    });
                                                                }}
                                                                beforeUpload={file => {
                                                                    this.setState({
                                                                        thumbnail: [file],
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {thumbnail.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={shapes}
                                    scroll={{x: 1400, y: 500}}
                                    components={this.components}
                                    onRow={(record, index) => ({
                                        index,
                                        moveRow: this.moveRow,
                                    })}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getShapes: Actions.getShapes,
        getTypeCategory: Actions.getTypeCategory,
        addShapes: Actions.addShapes,
        deleteShapes: Actions.deleteShapes,
        updateShapes: Actions.updateShapes,
        showMessage     : appActions.showMessage,
        setSortOrder: Actions.setSortOrder,
        getCatalogData: Actions.getCatalogData,

    }, dispatch);
}

function mapStateToProps({shapes, fuse})
{
    return {
        shapes: shapes.shapes.shapes,
        typeCategory: shapes.shapes.typeCategory,
        catalog: shapes.shapes.catalog,
        isLoading:fuse.loading.isLoading
    }
}
const ShapesComponent = Form.create()(DragDropContext(HTML5Backend)(Shapes));

export default withReducer('shapes', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(ShapesComponent))));