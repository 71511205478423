import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip} from 'antd';

const { Title } = Typography;
const {Option} = Select;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Catalog extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            brand_id: undefined,
            name : '',
            visible         : false,
            type            : "new",
            id              : null,
            basic_catalog_id: null,
        };
        props.getCatalog();
        props.getBrandCategory();
       
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                brand_id: undefined,
                name : '',
                type            : type,
                id              : null,
                basic_catalog_id: null,
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                brand_id: record.name?record.brand_id:"",
                name : record.name,
                type            : type,
                basic_catalog_id: record.basic_catalog_id,
            })
        }
        this.setState({
          visible: open,
        });
    };

    // canBeSubmitted()
    // {
    //     const {name, thumbnail, type} = this.state;
    //     return type==="new" ? (name.length > 0 && thumbnail.length > 0) : (name.length > 0);
    // }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              console.log('val', values, id);
              this.setState({visible: false});
                if(type === "new") {
                    this.props.addCatalog(values);
                }
                if(type === "edit" && id !== null) {
                    this.props.updateCatalog(values, id);
                }                
            }
            
          });
    }

    handleDeleteEvent = (record) => {
            const {deleteCatalog} = this.props;
            confirm({
                title: 'Do you want to delete this Item?',
                onOk() {
                    deleteCatalog(record)
                },
                onCancel() {},
            });
    }

    moveAction = (type, record) => () => {
        const {catalog} = this.props;
        const index = catalog.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && catalog[index-1].brand_id === record.brand_id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== catalog.length - 1 && catalog[index+1].brand_id === record.brand_id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: catalog[index].id, sort_order: catalog[swapIndex].sort_order});
            this.props.setSortOrder({id: catalog[swapIndex].id, sort_order: catalog[index].sort_order});
        }
    }


    render()
    {
        const { classes, catalog, brandCategory, isLoading} = this.props;
        const { id, brand_id, name, visible, type, basic_catalog_id} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;

        const brandItems = [];
        brandCategory.forEach(fElement => {
            brandItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.name}</Option>);
        })
        
        const catalogItems = [];
        catalog.forEach(fElement => {
            if (fElement.id != id) {
                catalogItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.name}</Option>);
            }
        })

        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 500,
            },
            {
                key: 'brand_name',
                title: 'Brand Name',
                dataIndex: 'brand_name',
                width: 550,
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Catalog"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            brand_id : this.state.brand_id,
                                            name     : this.state.name,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Catalog
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="name"
                                                        {...formItemLayout}
                                                        label="Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Name.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Catalog Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="brand_id"
                                                        {...formItemLayout}
                                                        label="Brand Name"
                                                    >
                                                        {getFieldDecorator("brand_id", {
                                                            initialValue: brand_id,
                                                            validateTrigger: 'onSelect',
                                                            rules: [{ required: true, message: 'Please select your brand!' }],
                                                        })(
                                                            (<Select
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select you brand"
                                                                treeDefaultExpandAll
                                                                onSelect={(value) => { this.setState({ brand_id: value}); }}
                                                            >
                                                                {brandItems}
                                                            </Select>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="basic_catalog_id"
                                                        {...formItemLayout}
                                                        label="Basic Catalog"
                                                    >
                                                        {getFieldDecorator("basic_catalog_id", {
                                                            initialValue: basic_catalog_id,
                                                        })(
                                                            (<Select
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select your basic catalog!"
                                                                treeDefaultExpandAll
                                                                allowClear={true}
                                                                onSelect={(value) => { this.setState({ basic_catalog_id: value}); }}
                                                            >
                                                                {catalogItems}
                                                            </Select>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={catalog}
                                    scroll={{x: 1400, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getCatalog: Actions.getCatalog,
        getBrandCategory: Actions.getBrandCategory,
        addCatalog: Actions.addCatalog,
        deleteCatalog: Actions.deleteCatalog,
        updateCatalog: Actions.updateCatalog,
        showMessage     : appActions.showMessage,
        setSortOrder: Actions.setSortOrder,

    }, dispatch);
}

function mapStateToProps({catalog, fuse})
{
    return {
        catalog: catalog.catalog.catalog,
        brandCategory: catalog.catalog.brandCategory,
        isLoading:fuse.loading.isLoading
    }
}
const CatalogComponent = Form.create()(Catalog);

export default withReducer('catalog', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(CatalogComponent))));