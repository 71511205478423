import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import {getDealers} from 'app/main/dealer_area/menu/home/store/actions';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const UPDATE_DEALER = '[DEALER_AREA] UPDATE_DEALER';
export function updateDealer(model, id, guid, dealerLogo, themeColor)
{
    const logoChanged = dealerLogo[0].originFileObj !== undefined ? true : false;
    var newData = {
        ...model,
        id, guid,
        themeColor,
        dealerLogo: logoChanged ? dealerLogo[0].name : null,
    };

    return (dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/dealer/update`, {
            ...newData
        });

        return request.then((res) =>
        {
            if (!res.data.success) {
                dispatch(showMessage({
                    message         : 'Email already exists',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    }, variant : 'warning'
                }));
                return;
            }
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                putFiles(dealerLogo.filter(file => file.originFileObj !== undefined), `uploads/dealer/${res.data.dealerData.guid + '_' + res.data.dealerData.id}/`).then(logo => {
                    const uploadData = [ 
                        {
                            fieldName: 'dealerLogo',
                            type: 'single',
                            data: logo
                        }
                    ];
                    axios.post(`${API_SERVER_URL}/api/base/updateUploadData`,
                        {
                            id: res.data.dealerData.id,
                            tableName: "dealers",
                            data: uploadData
                        }
                    )
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            dispatch(showMessage({
                                message         : 'Successfully Updated',
                                autoHideDuration: 2000,
                                anchorOrigin    : {
                                    vertical  : 'top',
                                    horizontal: 'right'
                                },variant : 'success'
                            }));
                                
                            dispatch(getDealers());
                        } else {
                            console.log(success);
                        }
                    })
                })
                .catch(err => {
                    console.log("failed to upload dealer logo. ", err);
                    return;
                });
            } else {
                const formData = new FormData();
                if (dealerLogo[0].originFileObj )
                formData.append("dealerLogo", dealerLogo[0].originFileObj);
                formData.append("themeColor", themeColor);
                formData.append("guid" , guid);
                formData.append("id" , id);
                axios.post(`${API_SERVER_URL}/api/dealer/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                dispatch(getDealers())
                            }
                        })
                    }
                }).catch(err => {
                    dispatch(showMessage({
                        message         : 'Failed to update dealer data',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    }));
        
                    console.log("err", err);
                });
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });
    };
}