import MainBackground from './MainBackground';
import {authRoles} from 'app/auth';

export const MainBackgroundConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/mainbackground',
            component: MainBackground
        }
    ]
};