import * as Actions from '../actions';

const initialState = {
    users: [],
    projects: [],
};

const leadsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_USERS:
        {
            return {
                ...state,
                users: action.payload
            }
        }
        case Actions.GET_PROJECTS:
        {
            return {
                ...state,
                projects: action.payload
            }
        }
        default:
        {
            return state;
        }
    }
};

export default leadsReducer;
