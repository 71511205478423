import React, {Component} from 'react';
import axios from 'axios';
import { API_SERVER_URL } from 'app/main/config';

export const GET_USERS = '[DEALER_AREA] GET USERS - LEADS';
export function getUsers() {
    return (dispatch) => {
        axios.post(`${API_SERVER_URL}/api/user/get_user`)
        .then(response => {
            return dispatch({
                type: GET_USERS,
                payload: response.data
            });
        })
    }
}
export const GET_PROJECTS = '[DEALER_AREA] GET PROJECTS - LEADS';
export function getProjects(username) {
    return (dispatch) => {
        axios.post(`${API_SERVER_URL}/api/savedprojects/read/savedprojects`, {username})
        .then(response => {
            return dispatch({
                type: GET_PROJECTS,
                payload: response.data.savedprojects
            });
        })
    }
}

export const DOWNLOAD_USER_DATA = '[DEALER_AREA] DOWNLOAD EXCEL - LEADS';
export function downloadUserData(data) {
    axios.post(`${API_SERVER_URL}/api/dealer/download_userdata`, data)
    .then(response => {
        const filename = response.data;

        let fileOutputLink = document.createElement('a');
        fileOutputLink.setAttribute("download", filename);
        fileOutputLink.href = `${API_SERVER_URL}/api/dealer/temp/${response.data}`;
        fileOutputLink.style.display = 'none';
        document.body.appendChild(fileOutputLink);
        fileOutputLink.click();
        document.body.removeChild(fileOutputLink);
    })
    return {
        type: DOWNLOAD_USER_DATA
    }
}