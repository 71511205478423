import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_MCATEGORIES = '[MCATEGORIES] GET MCATEGORIES';
export function getMcategories() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/mcategories/read/mcategories`)
        .then(response => {
            const {mcategories} = response.data;
                dispatch({
                    type:GET_MCATEGORIES,
                    mcategories: mcategories,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load MCATEGORIES data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });        
    })
}

export const GET_MOLDING_SHAPE = '[MCATEGORIES] GET MOLDING SHAPE';
export function getMoldingShape() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/moldingshape/read/moldingshape`)
        .then(response => {
            const {moldingshape, success} = response.data;
            if(success === true) {                
                dispatch({
                    type:GET_MOLDING_SHAPE,
                    moldingShape: moldingshape,
                })
            }else {
                Promise.all([
                    dispatch({
                        type: GET_MCATEGORIES
                    })
                ]).then(() => dispatch(getMcategories()))
            }
        })    
    })
}

export const GET_WALL_CABINET_LAYER = '[MCATEGORIES] GET WALL CABINET LAYER';
export function getWallCabinetLayer() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/categories/walllayer/categories`)
        .then(response => {
            const {categories, success} = response.data;
            if(success === true) {                
                dispatch({
                    type:GET_WALL_CABINET_LAYER,
                    wallCabinetLayer: categories,
                })
            }else {
                Promise.all([
                    dispatch({
                        type: GET_MCATEGORIES
                    })
                ]).then(() => dispatch(getMcategories()))
            }
        })    
    })
}


export const ADD_MCATEGORIES = '[MCATEGORIES] ADD MCATEGORIES';
export function addMcategories(data) {
    
    var newData = {
        molding_shape_id: data.molding_shape_id,
        wall_cabinet_layer_ids: JSON.stringify(data.wall_cabinet_layer_ids),
    }
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/mcategories/insert/mcategories`, {
            ...newData
        })
        .then(res => {
            if(res.data.success === true ){
                dispatch(showMessage({
                    message         : 'Successfully Inserted',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                Promise.all([
                    dispatch({
                        type: ADD_MCATEGORIES
                    })
                ]).then(() => dispatch(getMcategories()))
            }      
            
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });    
    })
}

export const DELETE_MCATEGORIES = '[MCATEGORIES] DELETE MCATEGORIES';
export function deleteMcategories(record) {
    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/mcategories/remove/mcategories`, {
                id: record.id
            })
            .then(response => {
                const {success} = response.data;
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                
                    Promise.all([
                        dispatch({
                            type: DELETE_MCATEGORIES
                        })
                    ]).then(() => dispatch(getMcategories()))
                }else{
                    dispatch(showMessage({
                        message: 'Some Door Colors are using this style',
                        autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'warning'
                    }));
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete MCATEGORIES data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
                console.log("err", err);
            });        
      
        
    })
}

export const UPDATE_MCATEGORIES = '[MCATEGORIES] UPDATE MCATEGORIES';
export function updateMcategories(data, id) {
    var updateData = {
        id                      : id,
        molding_shape_id: data.molding_shape_id,
        wall_cabinet_layer_ids: JSON.stringify(data.wall_cabinet_layer_ids),
    }

    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/mcategories/update/mcategories`, {
                ...updateData
            }).then(res => {
                const {success} = res.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: UPDATE_MCATEGORIES
                        })
                    ]).then(() => dispatch(getMcategories()))
                } else {
                    console.log(success);
                }
            }).catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to update MCATEGORIES data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
    
                console.log("err", err);
            });    
    })
}

export const SET_MCATEGORIES_SORT_ORDER = '[MCATEGORIES] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'mcategories'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_MCATEGORIES_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getMcategories()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in shape style.", err);
        })
    })
}