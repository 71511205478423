import React from 'react'
import { Form, Row, Icon, Input, Button, Checkbox } from 'antd'
import { Link } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as authActions from 'app/auth/store/actions';
import * as appActions from 'app/store/actions';
import * as Actions from 'app/main/dealer/store/actions';
import DealerReducer from 'app/main/dealer/store/reducers';
import '../style.css'
import DealerDialog from 'app/main/dealer/dialogs/DealerDialog';
import withReducer from 'app/store/withReducer';

class LoginFormComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email     : '',
      password  : '',
      isForgot  : false
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    const {isForgot, ...values} = this.state;
    if (isForgot) this.props.resetPassword(values);
    else this.props.submitLogin(values);
  }

  componentDidUpdate(prevProps, prevState)
  {
      if ( this.props.login.errorDealer && (this.props.login.errorDealer.info) )
      {
        this.props.showMessage({
            message: this.props.login.errorDealer.info, 
            autoHideDuration: 3000,anchorOrigin:{
                vertical  : 'top',
                horizontal: 'right'
        },variant : 'error'});

        this.props.login.errorDealer = null;
      }
      if ( this.props.login.resetDealer ) {
        this.props.showMessage({
          message: 'Your password is reseted to 123456', 
          autoHideDuration: 3000, anchorOrigin:{
              vertical  : 'top',
              horizontal: 'right'
        },variant : 'success'});

        this.props.login.resetDealer = false;
        this.setState({email: '', password: '', isForgot: false});
      }

      return null;
  }
  handleUsernameChange(e) {
    e.target.setCustomValidity("");
    this.setState({email: e.target.value});
  }
  handlePasswordChange(e) {
    e.target.setCustomValidity("");
    this.setState({password: e.target.value});
  }
  handleInputInvalid(e, text) {
    e.target.setCustomValidity("");
    if (!e.target.validity.valid) {
        e.target.setCustomValidity(text);
    }
  }
  handleForgotBtn() {
    const {isForgot} = this.state;
    this.setState({isForgot: !isForgot});
  }

  render() {
    const {getDealers, openNewDealerDialog} = this.props;
    const {email, password, isForgot} = this.state;

    return (
      <Form onSubmit={(e)=>this.handleSubmit(e)} className="dlogin-form">
        { isForgot &&
        <Row className="dlogin-form-row">
          <label className="form-label mb-0 text-white" style={{display:'flex', justifyContent:'center'}}> Please enter your email <br />to reset your password. </label>
        </Row>
        }
        <Row className="dlogin-form-row">
          <label className="form-label mb-0 text-white">E-mail</label>
            <input  
              value={email}
              onChange={(e) => this.handleUsernameChange(e)}
              onInvalid={e => this.handleInputInvalid(e, "Please enter your name")}
              placeholder="Email"
            />
        </Row>
        { !isForgot && 
        <Row className="dlogin-form-row">
          <label className="form-label mb-0 text-white">Password</label>
            <input
              value={password}
              onChange={(e) => this.handlePasswordChange(e)}
              onInvalid={e => this.handleInputInvalid(e, "Please enter your password")}
              type="password"
              placeholder="Password"
            />
        </Row>}
        <div className="login-form-actions">
          <button type="submit" className="dlogin-form-button">
            { isForgot ? 'RESET' : 'LOG IN' }
          </button>
        </div>
        <div style={{width: '90%', display: 'flex', justifyContent: 'space-around'}}>
          <div className="login-link-btn" style={{color: 'white'}} onClick={ e => this.handleForgotBtn() }> 
            { isForgot ? 'Back' : 'Forgot password' }
          </div>
          { !isForgot &&
          <div className="login-link-btn" style={{color: 'white'}} onClick={ e => { getDealers(); openNewDealerDialog(); } }> Create account </div>
          }
        </div>
        <DealerDialog/>
      </Form>
    )
  }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        submitLogin     : authActions.submitLoginDealer,
        resetPassword   : authActions.resetPassword,
        showMessage     : appActions.showMessage,
        getDealers      : Actions.getDealers,
        openNewDealerDialog: Actions.openNewDealerDialog,
    }, dispatch);
}

function mapStateToProps({auth})
{
    return {
        login: auth.login,
        dealer : auth.dealer
    }
}

const LoginForm = Form.create()(LoginFormComponent)
export default withReducer('dealersApp', DealerReducer)(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm)));
