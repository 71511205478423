import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles, getDoorStyleTreeData, addTimestampToFilename, } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export function getConstData() {
    return (dispatch => {
        dispatch(getMoldingShape());
        dispatch(getMainBackground());
    })
}

export const GET_MAIN_BACKGROUND = '[MOLDINGSHAPE] GET MAIN_BACKGROUND';
export function getMainBackground() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/mainbackground/read/main_background`)
        .then(response => {
            const {mainbackground, success} = response.data;
            if(success === true) {
                dispatch({
                    type:GET_MAIN_BACKGROUND,
                    mainbackgroundData: mainbackground,
                });
            }
        })    
    });
}

export const GET_MOLDINGSHAPE = '[MOLDINGSHAPE] GET MOLDINGSHAPE';
export function getMoldingShape() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/moldingshape/read/moldingshape`)
        .then(response => {
            const {moldingshape, success} = response.data;
            if(success === true) {     
                dispatch({
                    type:GET_MOLDINGSHAPE,
                    moldingshape,
                })
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to load MOLDINGSHAPE data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        })    
    })
}

export const ADD_MOLDINGSHAPE = '[MOLDINGSHAPE] ADD MOLDINGSHAPE';
export function addMoldingShape(data) {
    var newData = {
        png_layer_name  : data.png_layer_name,
        texture_name  : data.texture_name,
        cabinet_type_name: data.cabinet_type_name,
        main_background_id   : data.main_background_id,
        png_layer_url       : data.png_layer_url[0].name,
        texture_url         : data.texture_url.length === 0 ?  "" : data.texture_url[0].name,
        selected            : data.selected,
        transitions: data.transitions
    }

    if(data.png_layer_url[0].originFileObj !== undefined) data.png_layer_url[0].name = addTimestampToFilename(data.png_layer_url[0].name);
    if(data.texture_url.length !== 0 && data.texture_url[0].originFileObj !== undefined) data.texture_url[0].name = addTimestampToFilename(data.texture_url[0].name);

    const formData = new FormData();
    formData.append("png_layer_url", data.png_layer_url[0].originFileObj);
    formData.append("texture_url", data.texture_url.length === 0 ? null : data.texture_url[0].originFileObj);
    
    return ((dispatch, getState) => {
        axios.post(`${API_SERVER_URL}/api/moldingshape/insert/moldingshape`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.png_layer_url.filter(file => file.originFileObj !== undefined), `uploads/moldingshape/${res.data.moldingshape.guid + '_' +res.data.moldingshape.id}/png_layer_url/`).then(png_layer_url => {                        
                    uploadFiles(data.texture_url.filter(file => file.originFileObj !== undefined), `uploads/moldingshape/${res.data.moldingshape.guid + '_' +res.data.moldingshape.id}/texture_url/`).then(texture_url => {                        
                        const uploadData = [
                            {
                                fieldName: 'png_layer_url',
                                type: 'single',
                                data: png_layer_url
                            },
                            {
                                fieldName: 'texture_url',
                                type: 'single',
                                data: texture_url
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: res.data.moldingshape.id,
                                tableName: "moldingshape",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_MOLDINGSHAPE
                                    })
                                ]).then(() => dispatch(getMoldingShape()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to upload layer image. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to upload icon thumbnail. ", err);
                    return;
                })
            } else {
                formData.append("guid" , res.data.moldingshape.guid);
                formData.append("id" , res.data.moldingshape.id);   
                axios.post(`${API_SERVER_URL}/api/moldingshape/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type:ADD_MOLDINGSHAPE,
                                    })
                                ]).then(() => dispatch(getMoldingShape()))
                            }
                        })
                    }
                })   
            } 
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to add MOLDINGSHAPE data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });
    })
}

export const DELETE_MOLDINGSHAPE = '[MOLDINGSHAPE] DELETE MOLDINGSHAPE';
export function deleteMoldingShape(record) {
    return ((dispatch) => {
        var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production" ) {
                const data = [];
                data.push(record.png_layer_url);
                data.push(record.texture_url);
                deleteFiles(data).then(res => {
                    resolve("success");
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                })
            } else {
                resolve("success");
            }
        })
        p1.then(p1Value => {
            axios.post(`${API_SERVER_URL}/api/moldingshape/remove/moldingshape`, {
                id: record.id
            })
            .then(response => {
                const {success} = response.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type:DELETE_MOLDINGSHAPE,
                        })
                    ]).then(() => dispatch(getMoldingShape()))
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete MOLDINGSHAPE data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));

                console.log("err", err);
            });    
        })
        .catch (err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message: 'AWS S3 DELETE MOLDINGSHAPE ERROR',
                autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
            return;
        })
    })
}

export const UPDATE_MOLDINGSHAPE = '[MOLDINGSHAPE] UPDATE MOLDINGSHAPE';
export function updateMoldingShape(data, id, guid) {
    var updateData = {
        png_layer_name  : data.png_layer_name,
        texture_name  : data.texture_name,
        cabinet_type_name: data.cabinet_type_name,
        main_background_id   : data.main_background_id,
        png_layer_url       : jsonArray([data.png_layer_url[0]]),
        texture_url         : data.texture_url.length === 0 ?  "" : jsonArray([data.texture_url[0]]),
        selected            : data.selected,
        id              : id,
        transitions: data.transitions
    }

    if(data.png_layer_url[0].originFileObj !== undefined) data.png_layer_url[0].name = addTimestampToFilename(data.png_layer_url[0].name);
    if(data.texture_url.length !== 0 && data.texture_url[0].originFileObj !== undefined) data.texture_url[0].name = addTimestampToFilename(data.texture_url[0].name);

    return ((dispatch) => {
            
        axios.post(`${API_SERVER_URL}/api/moldingshape/update/moldingshape`, {
                ...updateData
            }).then(res => {
                dispatch(uploadLoading(true));
                if(process.env.REACT_APP_MODE === "production") {
                    putFiles(data.png_layer_url.filter(file => file.originFileObj !== undefined), `uploads/moldingshape/${guid + '_' + id}/png_layer_url/`).then(png_layer_url => {                        
                        putFiles(data.texture_url.filter(file => file.originFileObj !== undefined), `uploads/moldingshape/${guid + '_' + id}/texture_url/`).then(texture_url => {                        
                            const uploadData = [
                                {
                                    fieldName: 'png_layer_url',
                                    type: 'single',
                                    data: png_layer_url
                                },
                                {
                                    fieldName: 'texture_url',
                                    type: 'single',
                                    data: texture_url
                                }
                            ];
        
                            axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                                {
                                    id: id,
                                    tableName: "moldingshape",
                                    data: uploadData
                                }
                            )
                            .then(response => {
                                const {success} = response.data;
                                dispatch(uploadLoading(false));
                                if(success === true) {
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_MOLDINGSHAPE
                                        })
                                    ]).then(() => dispatch(getMoldingShape()))
                                } else {
                                    console.log(success);
                                }
                            })
                        })
                        .catch(err => {
                            console.log("failed to put cabinet texture. ", err);
                            return;
                        })
                    })
                    .catch(err => {
                        console.log("failed to put cabinet layer. ", err);
                        return;
                    })
                } else {
                    if(data.png_layer_url[0].originFileObj !== undefined || (data.texture_url.length !== 0 &&  data.texture_url[0].originFileObj !== undefined)) {
                        const formData = new FormData();
                        formData.append("guid" , guid);
                        formData.append("id" , id);
                        if(data.png_layer_url[0].originFileObj !== undefined) {
                            formData.append("png_layer_url", data.png_layer_url[0].originFileObj);
                        }
                        if(data.texture_url.length !== 0 &&  data.texture_url[0].originFileObj !== undefined) {
                            formData.append("texture_url", data.texture_url[0].originFileObj);
                        }
        
                        axios.post(`${API_SERVER_URL}/api/moldingshape/upload`, formData)
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                axios.post(`${API_SERVER_URL}/uploads/clear`)
                                .then(res => {
                                    if(res.data.success === true ){
                                        dispatch(showMessage({
                                            message         : 'Successfully Updated',
                                            autoHideDuration: 2000,
                                            anchorOrigin    : {
                                                vertical  : 'top',
                                                horizontal: 'right'
                                            },variant : 'success'
                                        }));
                                        Promise.all([
                                            dispatch({
                                                type:UPDATE_MOLDINGSHAPE,
                                            })
                                        ]).then(() => dispatch(getMoldingShape()))
                                    }
                                })
                            }
                        })
                    }
                    else {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message         : 'Successfully Updated',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'success'
                        }));
                        Promise.all([
                            dispatch({
                                type:UPDATE_MOLDINGSHAPE,
                            })
                        ]).then(() => dispatch(getMoldingShape()))
                    }
                }
            })
            .catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to update MOLDINGSHAPE data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
    
                console.log("err", err);
            });
         
    })
}

export const SET_MOLDINGSHAPE_SORT_ORDER = '[MOLDINGSHAPE] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'moldingshape'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_MOLDINGSHAPE_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getMoldingShape()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in door color.", err);
        })
    })
}