import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL } from 'app/main/config';

export const GET_EMAIL_LOG = '[PROJECT] GET EMAIL LOG';
export function getEmailLog() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/email/read/emaillog`)
        .then(response => {
            const {emaillog} = response.data;
            dispatch({
                type: GET_EMAIL_LOG,
                emaillog: emaillog,
            })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load LOG data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });
    })
}

export const GET_DEALERS = '[DEALERS APP] GET DEALERS';
export function getDealers()
{
    const request = axios.post(`${API_SERVER_URL}/api/dealer/get_dealer`);
    return (dispatch) => {
        request.then((response) => {
            const {dealers} = response.data;
            return dispatch({
                type: GET_DEALERS,
                dealers: response.data
            });
        });
    }
}