import * as Actions from '../actions';

const initialState = {
    subscription: [],
};

const subscriptionReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        default:
        {
            return state;
        }
    }
};

export default subscriptionReducer;
