import {combineReducers} from 'redux';
import user from './user.reducer';
import dealer from './dealer.reducer';
import login from './login.reducer';
import register from './register.reducer';

const authReducers = combineReducers({
    user,
    login,
    register,
    dealer,
});

export default authReducers;