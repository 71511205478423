import axios from 'axios';
import { showMessage } from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles, getDoorStyleTreeData, addTimestampToFilename, } from 'app/main/config';
import { uploadLoading } from 'app/store/actions/fuse';

export function getConstData() {
  return (dispatch => {
    dispatch(getCategories());
    dispatch(getMainBackground());
  })
}

export const GET_MAIN_BACKGROUND = '[CATEGORIES] GET MAIN_BACKGROUND';
export function getMainBackground() {
  return (dispatch => {
    axios.post(`${API_SERVER_URL}/api/mainbackground/read/main_background`)
      .then(response => {
        const { mainbackground, success } = response.data;
        if (success === true) {
          dispatch({
            type: GET_MAIN_BACKGROUND,
            mainbackgroundData: mainbackground,
          });
        }
      })
  });
}

export const GET_CATEGORIES = '[CATEGORIES] GET CATEGORIES';
export function getCategories() {
  return (dispatch => {
    axios.post(`${API_SERVER_URL}/api/categories/read/categories`)
      .then(response => {
        const { categories, success } = response.data;
        if (success === true) {
          dispatch({
            type: GET_CATEGORIES,
            categories,
          })
        }
      })
      .catch(err => {
        dispatch(showMessage({
          message: 'Failed to load CATEGORIES data',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }, variant: 'error'
        }));
        console.log("err", err);
      })
  })
}

export const ADD_CATEGORIES = '[CATEGORIES] ADD CATEGORIES';
export function addCategories(data) {
  var newData = {
    png_layer_name: data.png_layer_name,
    texture_name: data.texture_name,
    cabinet_type_name: data.cabinet_type_name,
    main_background_id: data.main_background_id,
    png_layer_url: data.png_layer_url[0].name,
    texture_url: data.texture_url.length === 0 ? "" : data.texture_url[0].name,
    selected: data.selected,
    parent_category_id: data.parent_category_id,
    transitions: data.transitions
  }

  if(data.png_layer_url[0].originFileObj !== undefined) data.png_layer_url[0].name = addTimestampToFilename(data.png_layer_url[0].name);
  if(data.texture_url.length !== 0 && data.texture_url[0].originFileObj !== undefined) data.texture_url[0].name = addTimestampToFilename(data.texture_url[0].name);

  const formData = new FormData();
  formData.append("png_layer_url", data.png_layer_url[0].originFileObj);
  formData.append("texture_url", data.texture_url.length === 0 ? null : data.texture_url[0].originFileObj);

  return ((dispatch, getState) => {
    axios.post(`${API_SERVER_URL}/api/categories/insert/categories`, {
      ...newData
    })
      .then(res => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          uploadFiles(data.png_layer_url.filter(file => file.originFileObj !== undefined), `uploads/categories/${res.data.categories.guid + '_' + res.data.categories.id}/png_layer_url/`).then(png_layer_url => {
            uploadFiles(data.texture_url.filter(file => file.originFileObj !== undefined), `uploads/categories/${res.data.categories.guid + '_' + res.data.categories.id}/texture_url/`).then(texture_url => {
              const uploadData = [
                {
                  fieldName: 'png_layer_url',
                  type: 'single',
                  data: png_layer_url
                },
                {
                  fieldName: 'texture_url',
                  type: 'single',
                  data: texture_url
                }
              ];
              axios.post(`${API_SERVER_URL}/api/base/updateUploadData`,
                {
                  id: res.data.categories.id,
                  tableName: "categories",
                  data: uploadData
                }
              )
                .then(response => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));
                  if (success === true) {
                    dispatch(showMessage({
                      message: 'Successfully Inserted',
                      autoHideDuration: 2000,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }, variant: 'success'
                    }));
                    dispatch(getCategories());
                  } else {
                    console.log(success);
                  }
                })
            })
              .catch(err => {
                console.log("failed to upload layer image. ", err);
                return;
              })
          })
            .catch(err => {
              console.log("failed to upload icon thumbnail. ", err);
              return;
            })
        } else {
          formData.append("guid", res.data.categories.guid);
          formData.append("id", res.data.categories.id);
          axios.post(`${API_SERVER_URL}/api/categories/upload`, formData)
            .then(response => {
              const { success } = response.data;
              dispatch(uploadLoading(false));
              if (success === true) {
                axios.post(`${API_SERVER_URL}/uploads/clear`)
                  .then(res => {
                    if (res.data.success === true) {
                      dispatch(showMessage({
                        message: 'Successfully Inserted',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right'
                        }, variant: 'success'
                      }));
                      dispatch(getCategories());
                    }
                  })
              }
            })
        }
      })
      .catch(err => {
        dispatch(showMessage({
          message: 'Failed to add CATEGORIES data',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }, variant: 'error'
        }));

        console.log("err", err);
      });
  })
}

export const DELETE_CATEGORIES = '[CATEGORIES] DELETE CATEGORIES';
export function deleteCategories(record) {
  return ((dispatch) => {
    var p1 = new Promise((resolve, reject) => {
      dispatch(uploadLoading(true));
      if (process.env.REACT_APP_MODE === "production") {
        const data = [];
        data.push(record.png_layer_url);
        data.push(record.texture_url);
        deleteFiles(data).then(res => {
          resolve("success");
        })
          .catch(err => {
            console.log(err);
            reject(err);
          })
      } else {
        resolve("success");
      }
    })
    p1.then(p1Value => {
      axios.post(`${API_SERVER_URL}/api/categories/remove/categories`, {
        id: record.id
      })
        .then(response => {
          const { success } = response.data;
          dispatch(uploadLoading(false));
          if (success === true) {
            dispatch(showMessage({
              message: 'Successfully Deleted',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }, variant: 'success'
            }));
            Promise.all([
              dispatch({
                type: DELETE_CATEGORIES,
              })
            ]).then(() => dispatch(getCategories()))
          }
        }).catch(err => {
          dispatch(uploadLoading(false));
          dispatch(showMessage({
            message: 'Failed to delete CATEGORIES data',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }, variant: 'error'
          }));

          console.log("err", err);
        });
    })
      .catch(err => {
        dispatch(uploadLoading(false));
        dispatch(showMessage({
          message: 'AWS S3 DELETE CATEGORIES ERROR',
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          variant: 'error'
        }));
        return;
      })
  })
}

export const UPDATE_CATEGORIES = '[CATEGORIES] UPDATE CATEGORIES';
export function updateCategories(data, id, guid) {
  var updateData = {
    cabinet_type_name: data.cabinet_type_name,
    id: id,
    main_background_id: data.main_background_id,
    parent_category_id: data.parent_category_id,
    png_layer_name: data.png_layer_name,
    png_layer_url: jsonArray([data.png_layer_url[0]]),
    selected: data.selected,
    texture_name: data.texture_name,
    texture_url: data.texture_url.length === 0 ? "" : jsonArray([data.texture_url[0]]),
    transitions: data.transitions
  };

  if(data.png_layer_url[0].originFileObj !== undefined) data.png_layer_url[0].name = addTimestampToFilename(data.png_layer_url[0].name);
  if(data.texture_url.length !== 0 && data.texture_url[0].originFileObj !== undefined) data.texture_url[0].name = addTimestampToFilename(data.texture_url[0].name);

  return dispatch => {
    axios
      .post('/api/categories/update/categories', updateData)
      .then(() => {
        dispatch(uploadLoading(true));
        if (process.env.REACT_APP_MODE === "production") {
          putFiles(data.png_layer_url.filter(file => file.originFileObj !== undefined), `uploads/categories/${guid + '_' + id}/png_layer_url/`).then(png_layer_url => {
            putFiles(data.texture_url.filter(file => file.originFileObj !== undefined), `uploads/categories/${guid + '_' + id}/texture_url/`).then(texture_url => {
              const uploadData = [
                {
                  fieldName: 'png_layer_url',
                  type: 'single',
                  data: png_layer_url
                },
                {
                  fieldName: 'texture_url',
                  type: 'single',
                  data: texture_url
                }
              ];

              axios.post(`${API_SERVER_URL}/api/base/updateUploadData`,
                {
                  id: id,
                  tableName: "categories",
                  data: uploadData
                }
              )
                .then(response => {
                  const { success } = response.data;
                  dispatch(uploadLoading(false));
                  if (success === true) {
                    dispatch(showMessage({
                      message: 'Successfully Updated',
                      autoHideDuration: 2000,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }, variant: 'success'
                    }));
                    Promise.all([
                      dispatch({
                        type: UPDATE_CATEGORIES
                      })
                    ]).then(() => dispatch(getCategories()))
                  } else {
                    console.log(success);
                  }
                })
            })
              .catch(err => {
                console.log("failed to put cabinet texture. ", err);
                return;
              })
          })
            .catch(err => {
              console.log("failed to put cabinet layer. ", err);
              return;
            })
        } else {
          if (data.png_layer_url[0].originFileObj !== undefined || (data.texture_url.length !== 0 && data.texture_url[0].originFileObj !== undefined)) {
            const formData = new FormData();
            formData.append("guid", guid);
            formData.append("id", id);
            if (data.png_layer_url[0].originFileObj !== undefined) {
              formData.append("png_layer_url", data.png_layer_url[0].originFileObj);
            }
            if (data.texture_url.length !== 0 && data.texture_url[0].originFileObj !== undefined) {
              formData.append("texture_url", data.texture_url[0].originFileObj);
            }

            axios.post(`${API_SERVER_URL}/api/categories/upload`, formData)
              .then(response => {
                const { success } = response.data;
                dispatch(uploadLoading(false));
                if (success === true) {
                  axios.post(`${API_SERVER_URL}/uploads/clear`)
                    .then(res => {
                      if (res.data.success === true) {
                        dispatch(showMessage({
                          message: 'Successfully Updated',
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                          }, variant: 'success'
                        }));
                        Promise.all([
                          dispatch({
                            type: UPDATE_CATEGORIES,
                          })
                        ]).then(() => dispatch(getCategories()))
                      }
                    })
                }
              })
          }
          else {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
              message: 'Successfully Updated',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }, variant: 'success'
            }));
            Promise.all([
              dispatch({
                type: UPDATE_CATEGORIES,
              })
            ]).then(() => dispatch(getCategories()))
          }
        }
      })
      .catch(err => {
        dispatch(showMessage({
          message: 'Failed to update CATEGORIES data',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }, variant: 'error'
        }));

        console.log("err", err);
      });
  }
}

export const SET_CATEGORIES_SORT_ORDER = '[CATEGORIES] SET SORT ORDER';
export function setSortOrder(data) {
  return ((dispatch) => {
    dispatch(uploadLoading(true));
    axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
      id: data.id,
      sort_order: data.sort_order,
      table_name: 'categories'
    })
      .then(response => {
        const { success } = response.data;
        if (success === true) {
          dispatch(uploadLoading(false));
          Promise.all([
            dispatch({
              type: SET_CATEGORIES_SORT_ORDER,
            })
          ]).then(() => dispatch(getCategories()))
        } else {
          dispatch(uploadLoading(false));
        }
      })
      .catch(err => {
        dispatch(uploadLoading(false));
        dispatch(showMessage({
          message: 'Failed to set sort order',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }, variant: 'error'
        }));
        console.log("failed to set sort order in door color.", err);
      })
  })
}

export const GET_CATALOG_DATA = '[CATALOG] GET CATALOG DATA';
export function getCatalogData() {
  return (dispatch => {
    axios.post(`${API_SERVER_URL}/api/catalog/read/catalog`)
      .then(response => {
        const { catalog } = response.data;
        dispatch({
          type: GET_CATALOG_DATA,
          catalog: catalog,
        })

      }).catch(err => {
        Promise.all([
          dispatch({
            type: GET_MAIN_BACKGROUND
          })
        ]).then(() => dispatch(getMainBackground()))
      });
  })
}