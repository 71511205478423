exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./normalize.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./print.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./tables.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./react-table.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./prism.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??postcss!./tailwind.css"), "");

// Module
exports.push([module.id, "* {\r\n    outline: none !important;\r\n}\r\n\r\nhtml {\r\n    font-size: 62.5%;\r\n    font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;\r\n    background-color: #262933;\r\n}\r\n\r\nbody {\r\n    font-size: 14px;\r\n    line-height: 1.4;\r\n}\r\n\r\nhtml, body, #root {\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow: hidden;\r\n    position: relative;\r\n}\r\n\r\nh1, .h1 {\r\n    font-size: 24px;\r\n}\r\n\r\nh2, .h2 {\r\n    font-size: 20px;\r\n}\r\n\r\nh3, .h3 {\r\n    font-size: 16px;\r\n}\r\n\r\nh4, .h4 {\r\n    font-size: 15px;\r\n}\r\n\r\nh5, .h5 {\r\n    font-size: 13px;\r\n}\r\n\r\nh6, .h6 {\r\n    font-size: 12px;\r\n}\r\n\r\n.ps > .ps__rail-y,\r\n.ps > .ps__rail-x {\r\n    z-index: 99;\r\n}\r\n\r\na[role=button] {\r\n    text-decoration: none;\r\n}\r\n\r\n/* Medium Devices, Desktops Only */\r\n@media only screen and (min-width: 992px) {\r\n    ::-webkit-scrollbar {\r\n        width: 12px;\r\n        height: 12px;\r\n        background-color: rgba(0, 0, 0, 0);\r\n    }\r\n\r\n    ::-webkit-scrollbar:hover {\r\n        width: 12px;\r\n        height: 12px;\r\n        background-color: rgba(0, 0, 0, 0.06);\r\n    }\r\n\r\n    ::-webkit-scrollbar-thumb {\r\n        border: 2px solid transparent;\r\n        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);\r\n        border-radius: 20px;\r\n    }\r\n\r\n    ::-webkit-scrollbar-thumb:active {\r\n        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);\r\n        border-radius: 20px;\r\n    }\r\n}\r\n\r\nform label {\r\n    z-index: 99;\r\n}\r\n\r\n.MuiFormControl-root.mb-36 {\r\n    margin-bottom: 36px !important;\r\n}\r\n", ""]);

