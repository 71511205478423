import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip} from 'antd';

const { Title } = Typography;

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class EmailLog extends Component {
   
    constructor(props) {
        super(props);
        props.getEmailLog();
        props.getDealers();
    }

    render()
    {
        const { classes, emaillog, isLoading, dealers} = this.props;

        const dealerData = [];
        dealers.forEach(fElement => {
            dealerData.push({
                text: fElement.url,
                value: fElement.url
            });
        });
        emaillog.forEach((fElement, idx) =>{
            if(fElement.instance == null) fElement.instance = ' ';
            fElement.key = idx;
        })
        dealerData.push({
            text: 'null',
            value: ' '
        })

        const columns = [
            {
                key: 'instance',
                title: 'Instance',
                dataIndex: 'instance',
                width: 200,
                filters: dealerData,
                onFilter: (value, record) => record.instance == value,
            },
            {
                key: 'from',
                title: 'From',
                dataIndex: 'from',
                width: 200,
            },
            {
                key: 'to',
                title: `To`,
                dataIndex: 'to',
                width: 200,
            },
            {
                key: 'sent_date',
                title: `Sent Date`,
                dataIndex: 'sent_date',
                width: 150,
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {`EmailLog - Total Count: ${emaillog.length}`}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Table 
                                    bordered
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={emaillog}
                                    scroll={{x: 1400, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getEmailLog: Actions.getEmailLog,
        getDealers: Actions.getDealers,
    }, dispatch);
}

function mapStateToProps({emaillog, fuse})
{
    return {
        emaillog: emaillog.emaillog.emaillog,
        isLoading:fuse.loading.isLoading,
        dealers: emaillog.emaillog.dealers,
    }
}
const EmailLogComponent = Form.create()(EmailLog);

export default withReducer('emaillog', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(EmailLogComponent))));