import React, {Component} from 'react';
import * as Actions from './store/actions';
import * as HomeActions from 'app/main/dealer_area/menu/home/store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Input, Form, Row, Col} from 'antd';
import ProjectsModal from './ProjectsModal';

const { Title } = Typography;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['5', '10', '20', '50'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
    titleFont: {
        fontFamily: 'MinionPro-Smbd',
    }
});

class LeadsComponent extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            visible         : false,
            type            : "new",
            id              : null,
            project_dlg_visible: false,
        };
        props.getUsers();
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                type            : type,
                id              : null,
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                username    : record.username,
                userid      : record.userid,
                email       : record.email,
                phone_num   : record.phone_num,
                type        : type,
            })
            this.props.getProjects(record.username);
        }
        this.setState({
          visible: open,
        });
    }

    moveAction = (type, record) => () => {
        const {users} = this.props;
        const index = users.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && users[index-1].id === record.id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== users.length - 1 && users[index+1].id === record.id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: users[index].id, sort_order: users[swapIndex].sort_order});
            this.props.setSortOrder({id: users[swapIndex].id, sort_order: users[index].sort_order});
        }
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    handleExportUserData = () => {
        const {users, user} = this.props;
        this.props.downloadUserData( users.filter(element => element.dealer_id === user.id ) );
    }

    render()
    {
        const { classes, users, user, projects, headerTitle} = this.props;
        const { username, userid, email, phone_num, project_dlg_visible, visible, type} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;

        const columns = [
            {
                key: 'fname',
                title: 'Name',
                dataIndex: 'fname',
                width: '10%',
                render: (text) => (
                    <span>{text === '' || !text ? 'N/A' : text}</span>
                )
            },
            {
                key: 'lname',
                title: 'Surname',
                dataIndex: 'lname',
                width: '10%',
                render: (text) => (
                    <span>{text === '' || !text ? 'N/A' : text}</span>
                )
            },
            {
                key: 'email',
                title: 'E-mail Address',
                dataIndex: 'email',
                width: '20%',
                render: (text) => (
                    <span>{text === '' || !text ? 'N/A' : text}</span>
                )
            },
            {
                key: 'phone_num',
                title: 'Phone No.',
                dataIndex: 'phone_num',
                width: '20%',
                render: (text) => (
                    <span>{text === '' || !text ? 'N/A' : text}</span>
                )
            },
            {
                key: 'message',
                title: 'Message',
                dataIndex: 'message',
                width: '25%',
                render: (text) => (
                    <span>{text === '' || !text ? 'N/A' : text}</span>
                )
            },
            {
                key: 'designSummary',
                title: 'Design Summary',
                dataIndex: 'id',
                width: '15%',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="Download">
                            <a href={record.design_pdf} target="_blank" download>
                                <DownloadIcon />
                            </a>
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
                <FusePageCarded
                    style={{height: 600}}
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-96 sm:min-h-96"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={2} className={clsx(classes.titleFont, "text-16 sm:text-20 truncate")}>
                                                {headerTitle}
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    contentToolbar={
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Title level={4} style={{marginLeft: "25px", fontFamily:'AvenirNextLTPro'}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                                {"Leads"}
                            </Title>
                            <div style={{marginLeft: 20, width: '1600px', height: 30, backgroundColor: '#cc5500'}}> </div>
                        </div>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                            <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            username, userid, phone_num, email
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            User Info
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="username"
                                                        {...formItemLayout}
                                                        label="Name"
                                                    >
                                                        {getFieldDecorator("username", {
                                                            initialValue: username,
                                                        })(
                                                            (<Input readOnly
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="userid"
                                                        {...formItemLayout}
                                                        label="User ID"
                                                    >
                                                        {getFieldDecorator("userid", {
                                                            initialValue: userid,
                                                        })(
                                                            (<Input readOnly
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="email"
                                                        {...formItemLayout}
                                                        label="Email"
                                                    >
                                                        {getFieldDecorator("email", {
                                                            initialValue: email,
                                                        })(
                                                            (<Input readOnly
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="phone_num"
                                                        {...formItemLayout}
                                                        label="Phone"
                                                    >
                                                        {getFieldDecorator("phone_num", {
                                                            initialValue: phone_num,
                                                        })(
                                                            (<Input readOnly
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter = {16}>
                                                <Col span={3} />                                                
                                                <Col span={10}>
                                                    <Button variant="contained" color="inherit" onClick={() => { this.setState({project_dlg_visible: true}); }}>
                                                        Saved Projects
                                                    </Button>
                                                </Col>
                                                {/* <Col span={6}>
                                                    <Button variant="contained" color="inherit" onClick={this.handleViewCDS}>
                                                        VIEW CDS
                                                    </Button>
                                                </Col> */}
                                            </Row>
                                        </Form>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Close
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <ProjectsModal
                                    anchor = "right"
                                    open = {project_dlg_visible}
                                    variant = "temporary"
                                    handleCancel = {() => {this.setState({project_dlg_visible: false})}}
                                    handleOk = {() => {this.setState({project_dlg_visible: false})}}
                                    defaultValue = {projects.backgroundData}
                                    handleEdit = {(record) => {this.handleEditCDS(record)}}
                                    handleDelete = {(record) => {this.handleDeleteCDS(record)}}
                                />
                                <Table
                                    rowKey="id"
                                    className="ctable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={users.filter(element => element.dealer_id === user.id )}
                                    scroll={{y: 270}}
                                    // onRowClick={(record) => { console.log(record);this.toggleDrawer("edit", record, true) }}
                                />
                                <Row className={'pt-88'}>
                                    <Button variant="contained" color="primary" onClick={e => this.handleExportUserData()} style={{ marginRight: 8, backgroundColor: '#cc5500', color: 'white' }}>
                                        DOWNLOAD CUSTOMER DATA
                                    </Button>
                                </Row>
                            </Row>
                        </div>
                    }
                />
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getUsers        : Actions.getUsers,
        getProjects     : Actions.getProjects,
        downloadUserData: Actions.downloadUserData,
        showMessage     : appActions.showMessage,
        setSortOrder    : HomeActions.setSortOrder,
    }, dispatch);
}

function mapStateToProps({auth, leads})
{
    return {
        user            : auth.dealer,

        users           : leads.leads.users,
        projects        : leads.leads.projects,
    }
}
const LeadsComp = Form.create()(LeadsComponent);

export default withReducer('leads', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(LeadsComp))));