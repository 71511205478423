import axios from 'axios';
import { API_SERVER_URL, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { showMessage, uploadLoading } from 'app/store/actions/fuse';

export const GET_DEALERS = '[DEALERS APP] GET DEALERS';
export const CONF_PERMISSION = '[DEALERS APP] CONF PERMISSION';
export const SET_SEARCH_TEXT = '[DEALERS APP] SET SEARCH TEXT';
export const TOGGLE_IN_SELECTED_DEALERS = '[DEALERS APP] TOGGLE IN SELECTED DEALERS';
export const SELECT_ALL_DEALERS = '[DEALERS APP] SELECT ALL DEALERS';
export const DESELECT_ALL_DEALERS = '[DEALERS APP] DESELECT ALL DEALERS';
export const OPEN_NEW_DEALER_DIALOG = '[DEALERS APP] OPEN NEW DEALER DIALOG';
export const CLOSE_NEW_DEALER_DIALOG = '[DEALERS APP] CLOSE NEW DEALER DIALOG';
export const OPEN_EDIT_DEALER_DIALOG = '[DEALERS APP] OPEN EDIT DEALER DIALOG';
export const CLOSE_EDIT_DEALER_DIALOG = '[DEALERS APP] CLOSE EDIT DEALER DIALOG';
export const ADD_DEALER = '[DEALERS APP] ADD DEALER';
export const UPDATE_DEALER = '[DEALERS APP] UPDATE DEALER';
export const REMOVE_DEALER = '[DEALERS APP] REMOVE DEALER';
export const REMOVE_DEALERS = '[DEALERS APP] REMOVE DEALERS';
export const TOGGLE_STARRED_DEALER = '[DEALERS APP] TOGGLE STARRED DEALER';
export const TOGGLE_STARRED_DEALERS = '[DEALERS APP] TOGGLE STARRED DEALERS';
export const SET_DEALERS_STARRED = '[DEALERS APP] SET DEALERS STARRED ';

export function getDealers()
{
    const request = axios.post(`${API_SERVER_URL}/api/dealer/get_dealer`);
    return (dispatch) => {
        request.then((response) => {
            return dispatch({
                type: GET_DEALERS,
                payload: response.data
            });
        });
    }
}

export const GET_CATALOG_CATEGORY = '[CATALOG] GET CATALOG CATEGORY';
export function getCatalogCategory() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/catalog/read/catalog`)
        .then(response => {
            const {catalog, success} = response.data;
            if(success === true) {     
                dispatch({
                    type: GET_CATALOG_CATEGORY,
                    catalogCategory: catalog,
                })
            }else {
                Promise.all([
                    dispatch({
                        type: GET_DEALERS
                    })
                ]).then(() => dispatch(getDealers()))
            }
        })    
    })
}

export function setSearchText(event)
{
    return {
        type      : SET_SEARCH_TEXT,
        searchText: event.target.value
    }
}


export function toggleInSelectedDealers(dealerId)
{
    return {
        type: TOGGLE_IN_SELECTED_DEALERS,
        dealerId
    }
}


export function selectAllDealers()
{
    return {
        type: SELECT_ALL_DEALERS
    }
}

export function deSelectAllDealers()
{
    return {
        type: DESELECT_ALL_DEALERS
    }
}


export function openNewDealerDialog()
{
    return {
        type: OPEN_NEW_DEALER_DIALOG
    }
}

export function closeNewDealerDialog()
{
    return {
        type: CLOSE_NEW_DEALER_DIALOG
    }
}

export function openEditDealerDialog(data)
{
    return {
        type: OPEN_EDIT_DEALER_DIALOG,
        data
    }
}

export function closeEditDealerDialog()
{
    return {
        type: CLOSE_EDIT_DEALER_DIALOG
    }
}

export function addDealer(model, dealerLogo, dealerBack, closeDialog)
{
    var newLogoData = {
        ...model,
        catalog_id: [model.catalog_id],
        dealerLogo: dealerLogo[0].name,
    };

    var newBackData = {
        ...model,
        catalog_id: [model.catalog_id],
        dealerBack: dealerBack[0].name,
    };

    return (dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/dealer/insert`, {
            ...newLogoData, ...newBackData
        });

        return request.then((res) =>
        {
            if (!res.data.success) {
                dispatch(showMessage({
                    message         : 'Email already exists',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    }, variant : 'warning'
                }));
                return;
            }
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(dealerLogo.filter(file => file.originFileObj !== undefined), `uploads/dealer/${res.data.dealerData.guid + '_' + res.data.dealerData.id}/`).then(logo => {
                    uploadFiles(dealerBack.filter(file => file.originFileObj !== undefined), `uploads/dealer/${res.data.dealerData.guid + '_' + res.data.dealerData.id}/`).then(back => {
                        const uploadData = [ 
                            {
                                fieldName: 'dealerLogo',
                                type: 'single',
                                data: logo
                            },
                            {
                                fieldName: 'dealerBack',
                                type: 'single',
                                data: back
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`,
                            {
                                id: res.data.dealerData.id,
                                tableName: "dealers",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                closeDialog();
                                Promise.all([
                                    dispatch({
                                        type: ADD_DEALER
                                    })
                                ]).then(() => dispatch(getDealers()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to upload dealer background. ", err);
                        return;
                    });
                })
                .catch(err => {
                    console.log("failed to upload dealer logo. ", err);
                    return;
                });
            } else {
                const formData = new FormData();
                formData.append("dealerLogo", dealerLogo[0].originFileObj);
                formData.append("dealerBack", dealerBack[0].originFileObj);
                formData.append("guid" , res.data.dealerData.guid);
                formData.append("id" , res.data.dealerData.id);
                axios.post(`${API_SERVER_URL}/api/dealer/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                closeDialog();
                                Promise.all([
                                    dispatch({
                                        type: ADD_DEALER
                                    })
                                ]).then(() => dispatch(getDealers()))
                            }
                        })
                    }
                }).catch(err => {
                    dispatch(showMessage({
                        message         : 'Failed to add dealer data',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    }));
        
                    console.log("err", err);
                });
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });
    };
}

export function updateDealer(model, id, guid, dealerLogo, dealerBack, closeDialog)
{
    const logoChanged = dealerLogo[0].originFileObj !== undefined ? true : false;
    var newLogoData = {
        ...model,
        id, guid,
        catalog_id: [model.catalog_id],
        dealerLogo: logoChanged ? dealerLogo[0].name : null,
    };
    const backChanged = dealerBack[0].originFileObj !== undefined ? true : false;
    var newBackData = {
        ...model,
        id, guid,
        catalog_id: [model.catalog_id],
        dealerBack: backChanged ? dealerBack[0].name : null,
    };
    return (dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/dealer/update`, {
            ...newLogoData, ...newBackData
        });
        return request.then((res) =>
        {
            if (!res.data.success) {
                dispatch(showMessage({
                    message         : 'Email already exists',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    }, variant : 'warning'
                }));
                return;
            }
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                putFiles(dealerLogo.filter(file => file.originFileObj !== undefined), `uploads/dealer/${res.data.dealerData.guid + '_' + res.data.dealerData.id}/`).then(logo => {
                    putFiles(dealerBack.filter(file => file.originFileObj !== undefined), `uploads/dealer/${res.data.dealerData.guid + '_' + res.data.dealerData.id}/`).then(back => {
                        const uploadData = [ 
                            {
                                fieldName: 'dealerLogo',
                                type: 'single',
                                data: logo
                            },
                            {
                                fieldName: 'dealerBack',
                                type: 'single',
                                data: back
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`,
                            {
                                id: res.data.dealerData.id,
                                tableName: "dealers",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                closeDialog();
                                Promise.all([
                                    dispatch({
                                        type: ADD_DEALER
                                    })
                                ]).then(() => dispatch(getDealers()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to upload dealer background. ", err);
                        return;
                    });
                })
                .catch(err => {
                    console.log("failed to upload dealer logo. ", err);
                    return;
                });
            } else {
                const formData = new FormData();
                if (dealerLogo[0].originFileObj ) formData.append("dealerLogo", dealerLogo[0].originFileObj);
                if (dealerBack[0].originFileObj ) formData.append("dealerBack", dealerBack[0].originFileObj);
                formData.append("guid" , guid);
                formData.append("id" , id);
                axios.post(`${API_SERVER_URL}/api/dealer/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                closeDialog();
                                Promise.all([
                                    dispatch({
                                        type: ADD_DEALER
                                    })
                                ]).then(() => dispatch(getDealers()))
                            }
                        })
                    }
                }).catch(err => {
                    dispatch(showMessage({
                        message         : 'Failed to update dealer data',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    }));
        
                    console.log("err", err);
                });
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });
    };
}

export function removeDealer(record) {
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/dealer/remove`, {
            id: record.id,
            visualizerName: record.visualizerName
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                var p1 = new Promise((resolve, reject) => {
                    dispatch(uploadLoading(true));
                    if(process.env.REACT_APP_MODE === "production" ) {
                        const data = [];
                        data.push(record.dealerLogo);
                        deleteFiles(data).then(res => {
                            resolve("success");
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err);
                        })
                    } else {
                        resolve("success");
                    }
                })
                p1.then(p1Value => {
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                
                    Promise.all([
                        dispatch({
                            type: REMOVE_DEALER
                        })
                    ]).then(() => dispatch(getDealers()))
                }).catch (err => {
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message: 'AWS S3 DELETE DEALER LOGO ERROR',
                        autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error'
                    }));
                    return;
                })
            }else{
                dispatch(showMessage({
                    message: 'Something wrong!',
                    autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'warning'
                }));
            }
        }).catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to delete logo data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });
    })
}
