import Mcategories from './Mcategories';
import {authRoles} from 'app/auth';

export const McategoriesConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/mcategories',
            component: Mcategories
        }
    ]
};