import axios from 'axios';
import { API_SERVER_URL } from 'app/main/config';
import { showMessage, uploadLoading }from 'app/store/actions/fuse';

export const GET_DEALERS = '[DEALER] GET DEALERS';
export function getDealers()
{
    const request = axios.post(`${API_SERVER_URL}/api/dealer/get_dealer`);
    return (dispatch) => {
        request.then((response) => {
            return dispatch({
                type: GET_DEALERS,
                payload: response.data
            });
        });
    }
}

export const GET_CATALOG_CATEGORY = '[CATALOG] GET CATALOG CATEGORY';
export function getCatalogCategory() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/catalog/read/catalog`)
        .then(response => {
            const {catalog, success} = response.data;
            if(success === true) {     
                dispatch({
                    type: GET_CATALOG_CATEGORY,
                    payload: catalog,
                })
            }else {
                Promise.all([
                    dispatch({
                        type: GET_DEALERS
                    })
                ]).then(() => dispatch(getDealers()))
            }
        })    
    })
}

export const SET_DEALER_SORT_ORDER = '[DEALER_AREA] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'dealers'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_DEALER_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getDealers()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in brand style.", err);
        })
    })
}