import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL } from 'app/main/config';
// import { uploadLoading }from 'app/store/actions/fuse';

export const LOAD_DATABASE = '[HOTSPOTS] LOAD DATABASE';
export function loadDB() {
    return (dispatch => {
        dispatch(loadMainbackground())
        dispatch(loadHotspots())
    })
}
export function loadMainbackground() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/mainbackground/read/main_background`)
        .then(response => {
            const {mainbackground, success} = response.data;
            if(success === true) {
                dispatch({
                    type:LOAD_DATABASE,
                    mainbackgrounds: mainbackground,
                });
            }
        })
    })
}

export function loadHotspots() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/hotspots/load`)
        .then(response => {
            const {hotspots, success} = response.data;
            if(success === true) {
                dispatch({
                    type:LOAD_DATABASE,
                    hotspots: hotspots,
                });
            }
        })
    })
}

export const INSERT_HOTSPOT = '[HOTSPOTS] INSERT HOTSPOT';
export function insertHotspot(mainbg_id, value) {
    const {x, y, type, nav_mainbg, rotateOnSelect} = value;
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/hotspots/insert`, { x, y, type, nav_mainbg, mainbg_id, rotateOnSelect })
        .then(response => {
            const {hotspots, success} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Inserted',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                dispatch({
                    type:INSERT_HOTSPOT,
                    hotspots: hotspots,
                });
            }
        })
    })
}

export const UPDATE_HOTSPOT = '[HOTSPOTS] UPDATE HOTSPOT';
export function updateHotspot(id, type, nav_mainbg, rotateOnSelect) {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/hotspots/update`, {id, type, nav_mainbg, rotateOnSelect})
        .then(response => {
            const {hotspots, success} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Updated',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                dispatch({
                    type:UPDATE_HOTSPOT,
                    hotspots: hotspots,
                });
            }
        })
    })
}

export const DELETE_HOTSPOT = '[HOTSPOTS] DELETE HOTSPOT';
export function deleteHotspot(id) {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/hotspots/delete`, {id})
        .then(response => {
            const {hotspots, success} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Deleted',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                dispatch({
                    type:UPDATE_HOTSPOT,
                    hotspots: hotspots,
                });
            }
        })
    })
}