import Shapes from './Shapes';
import {authRoles} from 'app/auth';

export const ShapesConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/shapes',
            component: Shapes
        }
    ]
};