import Leads from './Leads';
import {authRoles} from 'app/auth';

export const LeadsConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.dealer,
    routes  : [
        {
            path     : '/admin/leads',
            component: Leads
        }
    ]
};