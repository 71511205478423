import * as Actions from '../actions';

const initialState = {
    mainbackgrounds: [],
    hotspots: [],
};

const hostspotsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.LOAD_DATABASE:
        case Actions.INSERT_HOTSPOT:
        case Actions.UPDATE_HOTSPOT:
        {
            return {
                ...state,
                ...action,
            }   
        }
        default:
        {
            return state;
        }
    }
};

export default hostspotsReducer;
