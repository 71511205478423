import React, {Component} from 'react';
import * as Actions from './store/actions';
import * as HomeActions from 'app/main/dealer_area/menu/home/store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip} from 'antd';

const { Title } = Typography;
const {Option} = Select;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Content extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            visualizerName  : '',
            catalog_id      : [],
            visible         : false,
            type            : "new",
            id              : null,
        };
        props.getDealers();
        props.getCatalogCategory();
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                type            : type,
                id              : null,
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                guid        : record.guid,
                visualizerName: record.visualizerName,
                catalog_id  : record.catalog_id,
                type        : type,
            })
        }
        this.setState({
          visible: open,
        });
    };


    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              this.setState({visible: false});
                if(type === "edit" && id !== null) {
                    const dealer = this.props.dealers.filter(element => { return element.id === id })[0];
                    this.props.updateDealer(values, id, this.state.guid, dealer);
                }                
            }
        });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {dealers} = this.props;
        const index = dealers.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && dealers[index-1].theme === record.theme) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== dealers.length - 1 && dealers[index+1].theme === record.theme) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: dealers[index].id, sort_order: dealers[swapIndex].sort_order});
            this.props.setSortOrder({id: dealers[swapIndex].id, sort_order: dealers[index].sort_order});
        }
    }

    render()
    {
        const { classes, dealers, user, catalogCategory, isLoading} = this.props;
        const { visualizerName, catalog_id, visible, type} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;

        const catalogItems = [];
        catalogCategory && catalogCategory.forEach(fElement => {
            catalogItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.name}</Option>);
        })

        const columns = [
            {
                key: 'visualizerName',
                title: 'Visualizer Name',
                dataIndex: 'visualizerName',
                width: 200,
            },
            {
                key: 'catalog_name',
                title: 'Catalog',
                dataIndex: 'catalog_name',
                width: 200,
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 200,
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Your Content Info"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            visualizerName: visualizerName,
                                            catalog_id    : catalog_id[0],
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Brand
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="catalog_id"
                                                        {...formItemLayout}
                                                        label=" Catalog"
                                                    >
                                                        {getFieldDecorator("catalog_id", {
                                                            initialValue: catalog_id[0],
                                                            validateTrigger: 'onSelect',
                                                            rules: [{ required: true, message: 'Please select catalog!' }],
                                                        })(
                                                            (<Select
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select catalog"
                                                                treeDefaultExpandAll
                                                                onSelect={(value) => { this.setState({ catalog_id: [value]}); }}
                                                            >
                                                                {catalogItems}
                                                            </Select>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={dealers.filter(element => element.email === user.email )}
                                    scroll={{x: 1000, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getDealers      : HomeActions.getDealers,
        getCatalogCategory: HomeActions.getCatalogCategory,
        updateDealer    : Actions.updateDealer,
        showMessage     : appActions.showMessage,
        setSortOrder    : HomeActions.setSortOrder,
    }, dispatch);
}

function mapStateToProps({auth, fuse, home})
{
    return {
        isLoading       : fuse.loading.isLoading,
        dealers         : home.home.dealers,
        user            : auth.dealer,
        catalogCategory : home.home.catalogCategory,
    }
}
const ContentComponent = Form.create()(Content);

export default withReducer('content', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(ContentComponent))));