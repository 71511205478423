import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageSimple, FuseAnimate} from '@fuse';
import {Button, Fab, Popover, List, ListItem, Tooltip, ListItemAvatar} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Form, Spin, Row} from 'antd';
import HotspotAddDialog from './HotspotAddDialog';
import EyeIcon from "@material-ui/icons/RemoveRedEyeOutlined";

const { Title } = Typography;

var elementResizeDetectorMaker = require("element-resize-detector");
var erd = elementResizeDetectorMaker();

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
    },
    fab:{
        position:"fixed",
        backgroundColor:"#000000a8",
        '&:hover' :{
          backgroundColor:"#e0e2e4bf",
          color:"#000"
        }
    },
    btnfab : {
        margin: theme.spacing(1),
    },
    content: {
        // background : 'grey',
    },
    tooltipWidth: {
        maxWidth: 500,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
})

const fabsize = 20; // for small size

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      '& b': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
}))(Tooltip);

class Hotspots extends Component {
    anchorRef = React.createRef();

    constructor(props) {
        super(props);
        
        this.state = {
            width: 0,
            height: 0,
            posX: 0,
            posY: 0,
            curBGIdx: 0,

            adding: false,
            cursorX: 0,
            cursorY: 0,

            modalOpen: false,
            selectedId: null,
            selectedX: 0,
            selectedY: 0,
            selectedType: "",
            selectedMainBG: "",
            selectedRotateOnSelect: false,

            popoverOpen: false,
        }
        this.props.loadDB();
    }

    componentDidMount()
    {
        const _self = this;
        erd.listenTo(document.getElementById("background"), function(element) {
            var rect = element.getClientRects();
            var width = element.offsetWidth;
            // var height = element.offsetHeight;
            var maincontent = document.getElementById("maincontent").getClientRects();
            var headerRect = document.getElementById("pageheader").getClientRects();
            var height = maincontent[0].height - headerRect[0].height;
            if(width * 9/16 > height) {
                width = height * 16/9;
            }
            else {
                height = width * 9/16;
            }
            _self.setState({width, height , posX: rect[0].x, posY: rect[0].y})
        });
    }

    componentWillUnmount() {
        //erd.uninstall(document.getElementById("background"))
    }

    onMouseDown = (event) => {
        const {adding, width} = this.state;
        if(adding) {
            let x = event.target.x, y = event.target.y;

            if ( !( x && y ) ) { // In case of mousedown on a hotspot that is already exists
                let img = document.querySelector("#mainImg");
                x = img.x;
                y = img.y;
            }

            let pos_x = event.pageX - x;
            let pos_y = event.pageY - y;
            
            this.setState({adding: false, modalOpen: true, selectedId: null, selectedType: "", selectedMainBG: "", selectedX: pos_x / width * 100, selectedY: pos_y / (width * 9/16) * 100});
        }
    }

    onMouseMove = (event) => {
        const {adding} = this.state
        if(adding) {
            this.setState({cursorX: event.pageX, cursorY: event.pageY});
        }
    }

    updateState = (value) => () => {
        this.setState({adding: value, selectedId: null, selectedX: 0, selectedY: 0, selectedType: "", selectedMainBG: ""})
    }

    handleEdit = (el) => {
        this.setState({
            modalOpen: true, selectedId: el.id, selectedX: el.x, selectedY: el.y, selectedType: el.type, selectedMainBG: el.nav_mainbg,
            selectedRotateOnSelect: el.rotateOnSelect
        });
    }

    handleSaveClose = (newValue) => {
        this.setState({modalOpen: false});
        if(newValue != null) {
            const {mode, type, nav_mainbg, rotateOnSelect} = newValue;
            const {selectedId} = this.state;
            if(mode === "save") {
                if(selectedId == null) {
                    const {mainbackgrounds} = this.props;
                    const {curBGIdx} = this.state;
                    this.props.insertHotspot(mainbackgrounds[curBGIdx].id, newValue);
                }
                else {
                    this.props.updateHotspot(selectedId, type, nav_mainbg, rotateOnSelect);
                }
            }
            else if(mode === "delete") {
                this.props.deleteHotspot(selectedId);
            }
        }
    };

    handleSelectBGButton = () => {
        this.setState({popoverOpen: true});
    }
    handleSelectBGClose = () => {
        this.setState({popoverOpen: false});
    }

    handleSelectBGEvent = (key) => () => {
        this.setState({popoverOpen: false});
        this.setState({curBGIdx: key});
    }

    render()
    {
        const { classes, mainbackgrounds, hotspots, isLoading, deleteHotspot } = this.props;
        const { curBGIdx, width, posX, posY, adding, cursorX, cursorY, modalOpen, selectedId, selectedX, selectedY,
            selectedType, selectedMainBG, popoverOpen, selectedRotateOnSelect } = this.state;
        let hotspotsEls = null, existHotspots = [];
        if(hotspots.length > 0 && mainbackgrounds.length > 0) {
            hotspotsEls = hotspots.filter(el => el.mainbg_id === mainbackgrounds[curBGIdx].id).map((el, key) => {
                let tooltipTitle = "";
                tooltipTitle.concat("X : ", el.x.toFixed(2), "%, ", "Y : ", el.y.toFixed(2), "%");
                existHotspots.push(el);
                return (
                <HtmlTooltip key={key} title = {tooltipTitle}
                            classes={{tooltip: classes.tooltipWidth}}>
                    <Fab onClick={() => this.handleEdit(el)} style={{left:posX + width * el.x / 100 - fabsize, top:posY + (width*9/16) * el.y / 100 - fabsize}} color="primary" aria-label="Add" size="small" className={classes.fab}>
                        {el.type === "LookAt" ? <EyeIcon /> : <AddIcon fontSize="small" />}
                    </Fab>
                </HtmlTooltip>
                )
            })
        }
        let url = null;
        let mainbgEls = null;
        if(mainbackgrounds.length > 0) {
            mainbgEls = mainbackgrounds.map((el, key) => 
                <ListItem key={key} alignItems="flex-start" onClick={this.handleSelectBGEvent(key)}>
                    <ListItemAvatar>
                        <img width={320} height={180} src={el.thumbnail} alt="bg-select" />
                    </ListItemAvatar>
                </ListItem>
            )
            url = mainbackgrounds[curBGIdx].thumbnail;
        }
        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageSimple
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div id="pageheader" className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <div>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={this.updateState(true)}
                                    >
                                        Add
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        className={classes.button}
                                        ref={this.anchorRef} 
                                        onClick={this.handleSelectBGButton}>
                                        Select Background
                                    </Button>
                                </div>
                            </FuseAnimate>
                        </div>
                    }
                    content={
                        <div>
                            <Row id="background" 
                                onMouseDown={this.onMouseDown}
                                onMouseMove={this.onMouseMove}
                                className={classes.content}
                            >
                                {/* <div className="flex flex-1 w-full items-center justify-center"> */}
                                {url && (<img src={url} width={width} height={(width) * 9 / 16} alt="mainbg" id="mainImg" />)}
                                {/* </div> */}
                                {hotspotsEls}
                            </Row>
                            <HotspotAddDialog
                                open={modalOpen} onClose={this.handleSaveClose}
                                value={{id: selectedId, x: selectedX, y: selectedY, type: selectedType,
                                    nav_mainbg: selectedMainBG,
                                    rotateOnSelect: selectedRotateOnSelect
                                }}
                                mainbackgrounds={mainbackgrounds}
                                hotspots={existHotspots}
                                deleteHotspot={deleteHotspot}
                            />
                            {adding && (
                                <Fab style={{left:cursorX - fabsize, top:cursorY - fabsize, pointerEvents: 'none'}} color="primary" aria-label="Add" size="small" className={classes.fab}>
                                    <AddIcon fontSize="small" />
                                </Fab>
                            )}
                            <Popover
                                open={popoverOpen}
                                anchorEl={this.anchorRef.current}
                                anchorReference='anchorEl'
                                anchorPosition={{ top: 200, left: 400 }}
                                onClose={this.handleSelectBGClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <List className={classes.root}>
                                    {mainbgEls}
                                </List>
                            </Popover>
                        </div>    
                    }
                    sidebarInner
                    onRef={instance => {
                        this.pageLayout = instance;
                    }}
                    innerScroll
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        loadDB          : Actions.loadDB,
        insertHotspot   : Actions.insertHotspot,
        updateHotspot   : Actions.updateHotspot,
        deleteHotspot   : Actions.deleteHotspot,
    }, dispatch);
}

function mapStateToProps({hotspotsApp, fuse})
{
    const {hotspots} = hotspotsApp;
    return {
        mainbackgrounds: hotspots.mainbackgrounds,
        hotspots: hotspots.hotspots,
        isLoading:fuse.loading.isLoading
    }
}
const HotspotsComponent = Form.create()(Hotspots);

export default withReducer('hotspotsApp', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(HotspotsComponent))));