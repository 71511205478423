import React, { useState, useRef } from 'react';
import { Form, Input, Button, Select, Row, Col, Icon } from 'antd';
import { MinusCircleOutline, PlusOutline } from '@ant-design/icons';
import propTypes from 'prop-types';

let { Item } = Form;
let { Option } = Select;

let downStyle = {
  zIndex: 10000,
  maxHeight: 600,
  minWidth: 295,
  overflow: 'auto'
};

const TransitionList = ({
  transitions,
  mainBackgroundList,
  layerList,
  onChange: setTransition
}) => {
  let [search, setSearch] = useState('');

  // Applied Main backgrounds
  let maingbgIds = transitions.map(item => item.mainbg);

  // Main background Options
  let mainbgs = mainBackgroundList/*.filter(item => !maingbgIds.includes(item.id))*/.map(item => {
    return <Option key={item.id} value={item.id} disabled={maingbgIds.includes(item.id)}>{item.main_background_name}</Option>
  });

  // Layer Options
  // let layers = layerList
  //   .filter(item => (item.png_layer_name.includes(search)) && (item.cabinet_type_name === "None"))
  //   .map(item => (
  //     <Option key={item.id} value={item.id}>
  //       {item.png_layer_name}
  //     </Option>
  //   ));

  let content = transitions.map((item, idx) => (
    <Row key={idx}>
      <Col span={10}>
        <Item required>
          <Select
            dropdownStyle={downStyle}
            filterOption={false}
            onChange={v => {
              item.mainbg = v;
              setTransition([...transitions]);
            }}
            showSearch
            value={item.mainbg}
          >
            {mainbgs}
          </Select>
        </Item>
      </Col>
      <Col offset={2} span={10}>
        <Item required>
          <Select
            dropdownStyle={downStyle}
            filterOption={false}
            onChange={v => {
              item.layer = v;
              item.png_layer_url = layerList.find(d => d.id === v).png_layer_url;
              setTransition([...transitions]);
            }}
            onSearch={v => setSearch(v)}
            searchValue={search}
            showArrow
            showSearch
            value={item.layer}
          >
            {layerList.filter(l => (search === '' || l.png_layer_name.includes(search) || (l.id).toString().includes(search)) && l.cabinet_type_name === "None" && l.main_background_id === item.mainbg).map(l => (
              <Option key={l.id} value={l.id}>
                {l.png_layer_name}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={2}>
        <Icon
          type={MinusCircleOutline.name}
          style={{ display: 'block', margin: 'auto', marginTop: 8 }}
          //onClick={() => setTransition(transitions.filter(t => t.id !== item.id))}
          onClick={() => setTransition(transitions.filter((_, i) => i !== idx))}
        />
      </Col>
    </Row>
  ));

  return (
    <div>
      <Row style={{ marginBottom: 14 }}>
        <Col span={10}>Main background</Col>
        <Col offset={2} span={10}>Layer</Col>
      </Row>
      {content}
      <Button
        icon={PlusOutline.name}
        onClick={() => {
          if (transitions.some(item => item.mainbg === '' || item.layer === '')) {
            return;
          }
          setSearch('');
          transitions.push({
            //id: Date.now(),
            mainbg: '',
            layer: '',
            png_layer_url: ''
          });
          setTransition([...transitions]);
        }}
      >Add</Button>
    </div>
  );
};

TransitionList.propTypes = {
  transitions: propTypes.array.isRequired,
  mainBackgroundList: propTypes.array.isRequired, // Object array
  layerList: propTypes.array.isRequired, // Object array
  onChange: propTypes.func.isRequired
};

export default TransitionList;