import React from 'react';
import { connect } from 'react-redux';
import { Spin, Col } from 'antd';
import LeadsComponent from './LeadsComponent';

const Leads = (props) => {
    let {isLoading} = props;
    return (
        <Spin tip="Loading..." spinning={isLoading} wrapperClassName="pt-88">
            <Col span={18}>
                <LeadsComponent headerTitle="Lead Controller" />
            </Col>
        </Spin>
    )
}

function mapStateToProps({fuse})
{
    return {
        isLoading:fuse.loading.isLoading
    }
}

export default connect(mapStateToProps)(Leads);