import React, {Component} from 'react';
import {withStyles, Dialog, DialogContent,Button, DialogTitle, MenuItem, Icon, IconButton, Typography, Toolbar, AppBar,  InputAdornment} from '@material-ui/core';
import * as Actions from 'app/main/user/store/actions/index';
import {bindActionCreators} from 'redux';
import Formsy from 'formsy-react';
import {TextFieldFormsy, SelectFormsy} from '@fuse';
import {connect} from 'react-redux';

import classNames from 'classnames';
import _ from '@lodash';

const styles = theme => ({
    paper: {
        color: theme.palette.text.primary
    },
    iOSSwitchBase: {
        '&$iOSChecked': {
          color: theme.palette.common.white,
          '& + $iOSBar': {
            backgroundColor: '#52d869',
          },
        },
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});

const newUserState = {
    id      : '',
    name: '',
    role: '',
    password: ''
};

class UserDialog extends Component {
    
    state = {
        ...newUserState,
        dueMenu: null,
        canSubmit:false,
        checked: false,
        anchorEl   : null
    };

    handleMenuClose = () => {
        this.setState({anchorEl1: null});
    };

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        /**
         * After Dialog Open
         */
        if ( !prevProps.userDialog.props.open && this.props.userDialog.props.open )
        {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if ( this.props.userDialog.type === 'edit' &&
                this.props.userDialog.data &&
                !_.isEqual(this.props.userDialog.data, prevState) )
            {
                this.setState({...this.props.userDialog.data});
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if ( this.props.userDialog.type === 'new' &&
                !_.isEqual(newUserState, prevState) )
            {
                this.setState({...newUserState});
            }
        }
    }

    handleMenuClick = event => {
        this.setState({anchorEl1: event.currentTarget});
    };

    closeComposeDialog = () => {
        this.props.userDialog.type === 'edit' ? this.props.closeEditUserDialog() : this.props.closeNewUserDialog();
        this.setState({checked: false});
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
       
        if(this.props.userDialog.type === "new") {
            this.props.addUser(model);
            this.props.closeNewUserDialog();
        } else{
            this.props.updateUser(model, this.props.userDialog.data.id);
            this.props.closeEditUserDialog();
        }
        
    };

    handleCheckedChange = e => {
        this.setState({checked: e.target.checked});
    }

    render()
    {
        const {userDialog, user, classes} = this.props;
        const {canSubmit} = this.state;

        return (
            <Dialog
                classes={{
                    paper: classNames(classes.paper, "max-w-lg w-full m-24")
                }}
                onClose={this.closeComposeDialog}
                {...userDialog.props}
            >

                {userDialog && (
                    <DialogTitle component="div" className="p-0">
                        <AppBar position="static" elevation={1}>
                            <Toolbar className="flex w-full overflow-x-auto px-8 sm:px-16">
                                <div className="flex flex-1">
                                    <Typography color="inherit" className="mt-16" variant="h6">Edit</Typography>
                                </div>
                                <IconButton color="inherit" onClick={this.closeComposeDialog}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>
                )}

                {userDialog && (
                    <DialogContent className="p-16 sm:p-24">

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            ref={(form) => this.form = form}
                        >
                            <TextFieldFormsy
                                className="w-full mb-24"
                                type="text"
                                name="username"
                                label="User Name"
                                validations={{
                                    minLength: 2
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 2.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">person</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.userDialog.type === "new" ? "" : this.state.username}
                                                   
                            />
                            <TextFieldFormsy
                                className="w-full mb-24"
                                type="text"
                                name="userid"
                                label="User ID"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.userDialog.type === "new" ? "" : this.state.userid}   
                              
                            />
                              {/* <TextFieldFormsy
                                className="w-full mb-24"
                                type="text"
                                name="role"
                                label="User Role"
                                validations={{
                                    minLength: 3
                                }}
                                validationErrors={{
                                    minLength: 'minLength is less than 3.'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">face</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                                value={this.props.userDialog.type === "new" ? "" : this.state.role}   
                              
                            /> */}
                            <SelectFormsy
                                className="w-full mb-24"
                                name="role"
                                label="User Role"
                                required
                                variant="outlined"
                                value={this.props.userDialog.type === "new" ? "" : this.state.role}
                            >
                                {this.props.userDialog.type !== "new" && (user.role === 'superadmin' || user.role === 'superdesigner') && 
                                <MenuItem value="superadmin">superAdmin</MenuItem>}
                                {this.props.userDialog.type !== "new" && (user.role === 'superadmin' || user.role === 'superdesigner') && 
                                <MenuItem value="superdesigner">superDesigner</MenuItem>}
                                <MenuItem value="customer">customer</MenuItem>
                                <MenuItem value="designer">designer</MenuItem>
                            </SelectFormsy>
                            <TextFieldFormsy
                                className="w-full mb-24"
                                type="password"
                                name="password"
                                label="Password"
                                validations="equalsField:password-confirm"
                                validationErrors={{
                                    equalsField: 'Please confirm your password'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">vpn_key</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required={this.props.userDialog.type === "new" ? true : true}
                                value=""
                            />
                            <TextFieldFormsy
                                className="w-full mb-24"
                                type="password"
                                name="password-confirm"
                                label="Confirm Password"
                                validations="equalsField:password"
                                validationErrors={{
                                    equalsField: 'Please confirm your password'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">vpn_key</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required={this.props.userDialog.type === "new" ? true : true}
                                value=""
                            />
                            {userDialog.type === 'new' ? (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="mx-auto mt-16 normal-case"
                                    aria-label="REGISTER"
                                    disabled={!canSubmit}
                                    value="legacy"
                                >
                                    Add
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className="mx-auto mt-16 normal-case"
                                    aria-label="REGISTER"
                                    disabled={!canSubmit}
                                    value="legacy"
                                >
                                    Save
                                </Button>
                            )}
                            

                        </Formsy>
                    </DialogContent>
                )}
            </Dialog>
        );
    }
}


function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        closeEditUserDialog: Actions.closeEditUserDialog,
        closeNewUserDialog : Actions.closeNewUserDialog,
        addUser            : Actions.addUser,
        updateUser         : Actions.updateUser,
        removeUser         : Actions.removeUser,
    }, dispatch);
}

function mapStateToProps({usersApp, auth})
{
    return {
        userDialog: usersApp.users.userDialog,
        users     : usersApp.users.entities,
        user      : auth.user
    }
}


export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(UserDialog));
