import { FuseAnimate, FusePageCarded } from '@fuse';
import { Button, Divider, Drawer, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Col, Form, Input, Modal, Row, Spin, Table, Tooltip, Typography, Upload } from 'antd';
import * as appActions from 'app/store/actions';
import withReducer from 'app/store/withReducer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import reducer from './store/reducers';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

const defaultPagination = {
  pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
  showSizeChanger: true,
  size: 'small',
  position: 'top',
}

const styles = theme => ({
  button: {
    marginTop: "-12px",
  },
  input: {
    display: 'none',
  },
  list: {
    width: 500,
  },
});

class Layer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      thumbnail: [],
      visible: false,
      type: "new",
      id: null,
    };
    props.getLayer();
  }

  toggleDrawer = (type, record, open) => () => {
    if (type === "new") {
      this.setState({
        name: '',
        type: type,
        id: null,
        thumbnail: [],
      })
    }
    else if (type === "edit") {
      this.setState({
        id: record.id,
        guid: record.guid,
        name: record.name,
        type: type,
        thumbnail: [{
          uid: 1,
          name: record.thumbnail.split('/').pop(),
          status: 'done',
          url: record.thumbnail
        }],
      })
    }
    this.setState({
      visible: open,
    });
  };

  // canBeSubmitted()
  // {
  //     const {door_style_name, thumbnail, type} = this.state;
  //     return type==="new" ? (door_style_name.length > 0 && thumbnail.length > 0) : (door_style_name.length > 0);
  // }

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type, id } = this.state;
        this.setState({ visible: false });
        if (type === "new") {
          this.props.addLayer(values);
        }
        if (type === "edit" && id !== null) {
          this.props.updateLayer(values, id, this.state.guid);
        }

      }

    });
  }

  handleDeleteEvent = (record) => {
    const { deleteLayer } = this.props;
    confirm({
      title: 'Do you want to delete this Item?',
      onOk() {
        deleteLayer(record)
      },
      onCancel() { },
    });
  }

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  moveAction = (type, record) => () => {
    const { layerData } = this.props;
    const index = layerData.findIndex(el => el.id === record.id);
    let swapIndex = -1;
    if (type === "up" && index !== 0 && layerData[index - 1].door_category_id === record.door_category_id) {
      swapIndex = index - 1;
    }
    else if (type === "down" && index !== layerData.length - 1 && layerData[index + 1].door_category_id === record.door_category_id) {
      swapIndex = index + 1;
    }
    if (swapIndex !== -1) {
      this.props.setSortOrder({ id: layerData[index].id, sort_order: layerData[swapIndex].sort_order });
      this.props.setSortOrder({ id: layerData[swapIndex].id, sort_order: layerData[index].sort_order });
    }
  }


  render() {
    const { classes, layerData, isLoading } = this.props;
    const { thumbnail, name, visible, type } = this.state;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    const uploadButton = (
      <div>
        <AddIcon />
        <div className="ant-upload-text">
          {type === "new" ? "Upload" : "Change & Upload"}
        </div>
      </div>
    );
    // const loop = data => data.map((item) => {
    //     if (item.children && item.children.length) {
    //         return <TreeNode key={item.id.toString()} value={item.id} title={item.name}>{loop(item.children)}</TreeNode>;
    //     }
    //     else {
    //         return <TreeNode key={item.id.toString()} value={item.id} title={item.name}/>;
    //     }
    // });
    const columns = [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        width: 200,
      },
      {
        key: 'thumbnail',
        title: 'Thumbnail',
        dataIndex: 'thumbnail',
        colSpan: 2,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            <span>{text}</span>
          </Tooltip>
        )
      },
      {
        key: 'preview',
        dataIndex: 'thumbnail',
        colSpan: 0,
        width: 150,
        render: (text, record) => (
          <Row className="list__thumbnail">
            <img src={text} alt="" />
          </Row>
        ),
      },
      {
        key: 'action',
        title: 'Action',
        dataIndex: 'id',
        width: 250,
        fixed: 'right',
        render: (text, record) => (
          <Row>
            <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
              <EditIcon />
            </IconButton>
            <IconButton className={classes.button} aria-label="Edit" onClick={e => { e.preventDefault(); this.handleDeleteEvent(record); }}>
              <DeleteIcon />
            </IconButton>
          </Row>
        ),
      },
    ];

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Title level={4} className="text-16 sm:text-20 truncate">
                      </Title>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  onClick={this.toggleDrawer("new", null, true)}
                >
                  Add
                </Button>
              </FuseAnimate>
            </div>
          }
          contentToolbar={
            <Title level={4} style={{ marginLeft: "25px" }} className="text-16 sm:text-20 truncate" classes={{ root: "w-full h-64" }}>
              {"Layer Mask Image"}
            </Title>
          }
          content={
            <div className="p-24">
              <Row>
                <Drawer
                  anchor="right"
                  open={visible}
                  variant="temporary"
                  onClose={this.toggleDrawer(null, null, false)}
                  classes={{ paper: classes.list }}
                  onRendered={() => {
                    var data = {
                      name: this.state.name,
                      thumbnail: this.state.thumbnail,
                    }
                    setFieldsValue({ ...data })
                  }}
                >
                  <div
                    tabIndex={0}
                    role="button"
                  >
                    <Title level={3} className="pt-16" style={{ textAlign: "center" }}>
                      {type === "new" ? "Add" : "Edit"} Layer Mask Image
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{ marginTop: "20px" }}>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="name"
                            {...formItemLayout}
                            label="Layer Mask Image Name"
                          >
                            {getFieldDecorator("name", {
                              initialValue: name,
                              rules: [{
                                required: true, message: 'Enter Layer Mask Image Name.',
                              }],
                            })(
                              (<Input
                                placeholder="Enter Layer Mask Image Name"
                                onChange={(e) => {
                                  this.setState({ name: e.target.value });
                                }}
                              />)
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={2} />
                        <Col span={22}>
                          <FormItem
                            key="Thumbnail"
                            {...formItemLayout}
                            label="Thumbnail"
                          >
                            {getFieldDecorator("thumbnail", {
                              initialValue: thumbnail,
                              valuePropName: 'fileList',
                              rules: [{ required: true, message: 'Please select Thumbnail!' }],
                              getValueFromEvent: this.normFile,
                            })(
                              (<Upload
                                listType="picture-card"
                                className="upload-list-inline"
                                onRemove={file => {
                                  this.setState({
                                    thumbnail: [],
                                  });
                                }}
                                beforeUpload={file => {
                                  this.setState({
                                    thumbnail: [file],
                                  });
                                  return false;
                                }}
                              >
                                {thumbnail.length > 0 ? null : uploadButton}
                              </Upload>)
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Form>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                      }}
                    >
                      <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                        Cancel
                      </Button>
                      <Button
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                      // disabled={!this.canBeSubmitted()}
                      >
                        {type === "new" ? "ADD" : "UPDATE"}
                      </Button>
                    </div>
                  </div>
                </Drawer>
                <Table
                  bordered
                  rowKey="id"
                  className="mtable"
                  pagination={defaultPagination}
                  columns={columns}
                  dataSource={layerData}
                  scroll={{ x: 1400, y: 500 }}
                />
              </Row>
            </div>
          }
        />
      </Spin>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLayer: Actions.getLayer,
    addLayer: Actions.addLayer,
    deleteLayer: Actions.deleteLayer,
    updateLayer: Actions.updateLayer,
    showMessage: appActions.showMessage,
    setSortOrder: Actions.setSortOrder,

  }, dispatch);
}

function mapStateToProps({ layer, fuse }) {
  return {
    layerData: layer.layer.layer,
    isLoading: fuse.loading.isLoading
  }
}
const LayerComponent = Form.create()(Layer);

export default withReducer('layer', reducer)(withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(LayerComponent))));