import React, { Component } from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import { withRouter } from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import { Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip, Button } from 'antd';

const { Title } = Typography;

const { Option } = Select;


const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class UrlGenerator extends Component {

    state = {
        prefix_url: "https://fabuwood360.addovisuals.com",
        brandId: null,
        catalogId: null,
        backId: null,
        walldoor: 1,
        basedoor: 1,
        islanddoor: 1,
        crown: 1,
        countertop: 1,
        backsplash: 1,
        floor: 1,
        appliance: 1,
        dynamic_url: ""
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getMainbackgroundData();
        this.props.getBrandData();
        this.props.getCatalogData();
        this.props.getDealerData();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(prevState)
        if (prevState.backId !== this.state.backId && this.state.backId !== null) {
            this.props.getLayerAndMoldingData(this.state.backId);
        }
    }

    getDynamicUrlLink = () => {
        const { mainbackground, brand } = this.props.urlgenerator.urlgenerator;
        const projecttype = mainbackground.filter(it => it.id === this.state.backId)[0].room_flag === 1 ? 1 : 0;
        const dynamic_url = `${this.state.prefix_url}/main/${this.state.backId}/${projecttype}/${this.state.walldoor}/${this.state.basedoor}/${this.state.islanddoor}/${this.state.crown}/${this.state.countertop}/${this.state.backsplash}/${this.state.floor}/${this.state.appliance}`;
        this.setState({ dynamic_url })
    }

    render() {
        const { classes, isLoading, urlgenerator } = this.props;
        const { mainbackground, layerData, moldingData, brand, catalog } = urlgenerator.urlgenerator;

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{ marginLeft: "25px" }} className="text-16 sm:text-20 truncate" classes={{ root: "w-full h-64" }}>
                            {"Dynamic URL"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Col span={8}>
                                    <h4>
                                        Prefix URL
                                    </h4>
                                    <br />
                                    <Input value={this.state.prefix_url} onChange={(evt) => this.setState({ prefix_url: evt.target.value })} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={4}>
                                    <h4>
                                        Brand
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Brand"
                                        onSelect={(v) => {
                                            this.setState({ brandId: v, catalogId: null, backId: null, walldoor: 1, basedoor: 1, islanddoor: 1, crown: 1, countertop: 1, backsplash: 1, floor: 1, appliance: 1 })
                                        }}
                                    >
                                        {
                                            brand.map(it => <Option key={it.id} value={it.id}>{it.name}</Option>)
                                        }
                                    </Select>
                                </Col>
                                <Col span={6} offset={2}>
                                    <h4>
                                        Catalog
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Brand"
                                        onSelect={(v) => {
                                            this.setState({ catalogId: v, backId: null, walldoor: 1, basedoor: 1, islanddoor: 1, crown: 1, countertop: 1, backsplash: 1, floor: 1, appliance: 1 })
                                        }}
                                    >
                                        {
                                            catalog.map(it => it.brand_id === this.state.brandId && <Option key={it.id} value={it.id}>{it.name}</Option>)
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={8}>
                                    <h4>
                                        Room ID
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Mainbackground"
                                        onSelect={(v) => {
                                            this.setState({ backId: v, walldoor: 1, basedoor: 1, islanddoor: 1, crown: 1, countertop: 1, backsplash: 1, floor: 1, appliance: 1 })
                                        }}
                                    >
                                        {
                                            mainbackground.map(it => it.catalog_id === this.state.catalogId && <Option key={it.id} value={it.id}>{it.main_background_name}</Option>)
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={6}>
                                    <h4>
                                        Wall Door
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || layerData.filter(it => it.cabinet_type_name === "Wall Cabinets").length === 0 ? true : false}
                                        value={this.state.walldoor}
                                        placeholder="Select Wall Door"
                                        onSelect={(v) => this.setState({ walldoor: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            layerData.map(it =>
                                                it.cabinet_type_name === "Wall Cabinets" &&
                                                <Option key={it.id} value={it.id}>{it.texture_name}</Option>)
                                        }
                                    </Select>
                                </Col>
                                <Col span={6} offset={2}>
                                    <h4>
                                        Base Door
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || layerData.filter(it => it.cabinet_type_name === "Base Cabinets").length === 0 ? true : false}
                                        value={this.state.basedoor}
                                        placeholder="Select Base Door"
                                        onSelect={(v) => this.setState({ basedoor: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            layerData.map(it =>
                                                it.cabinet_type_name === "Base Cabinets" &&
                                                <Option key={it.id} value={it.id}>{it.texture_name}</Option>)
                                        }
                                    </Select>
                                </Col>
                                <Col span={6} offset={2}>
                                    <h4>
                                        Island Door
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || layerData.filter(it => it.cabinet_type_name === "Island Cabinets").length === 0 ? true : false}
                                        value={this.state.islanddoor}
                                        placeholder="Select Island Door"
                                        onSelect={(v) => this.setState({ islanddoor: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            layerData.map(it =>
                                                it.cabinet_type_name === "Island Cabinets" &&
                                                <Option key={it.id} value={it.id}>{it.texture_name}</Option>)
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={6}>
                                    <h4>
                                        Crown
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || moldingData.filter(it => JSON.parse(it.wall_cabinet_layer_ids).includes(this.state.walldoor)).length === 0 ? true : false}
                                        value={this.state.crown}
                                        placeholder="Select Crown"
                                        onSelect={(v) => this.setState({ crown: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            moldingData.map(it => JSON.parse(it.wall_cabinet_layer_ids).includes(this.state.walldoor)
                                                && <Option key={it.id} value={it.id}>{it.texture_name}</Option>
                                            )
                                        }
                                    </Select>
                                </Col>
                                <Col span={6} offset={2}>
                                    <h4>
                                        Countertop
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || layerData.filter(it => it.cabinet_type_name === "Countertop").length === 0 ? true : false}
                                        value={this.state.countertop}
                                        placeholder="Select Countertop"
                                        onSelect={(v) => this.setState({ countertop: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            layerData.map(it =>
                                                it.cabinet_type_name === "Countertop" &&
                                                <Option key={it.id} value={it.id}>{it.texture_name}</Option>)
                                        }
                                    </Select>
                                </Col>

                                <Col span={6} offset={2}>
                                    <h4>
                                        Backsplash
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || layerData.filter(it => it.cabinet_type_name === "Backsplash").length === 0 ? true : false}
                                        value={this.state.backsplash}
                                        placeholder="Select BackSplash"
                                        onSelect={(v) => this.setState({ backsplash: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            layerData.map(it =>
                                                it.cabinet_type_name === "Backsplash" &&
                                                <Option key={it.id} value={it.id}>{it.texture_name}</Option>)
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={6}>
                                    <h4>
                                        Floor
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || layerData.filter(it => it.cabinet_type_name === "Floor").length === 0 ? true : false}
                                        value={this.state.floor}
                                        placeholder="Select Floor"
                                        onSelect={(v) => this.setState({ floor: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            layerData.map(it =>
                                                it.cabinet_type_name === "Floor" &&
                                                <Option key={it.id} value={it.id}>{it.texture_name}</Option>)
                                        }
                                    </Select>
                                </Col>
                                <Col span={6} offset={2}>
                                    <h4>
                                        Appliance
                                    </h4>
                                    <br />
                                    <Select
                                        style={{ width: "100%" }}
                                        disabled={this.state.backId === null || layerData.filter(it => it.cabinet_type_name === "Appliances").length === 0 ? true : false}
                                        value={this.state.appliance}
                                        placeholder="Select Appliance"
                                        onSelect={(v) => this.setState({ appliance: v })}
                                    >
                                        <Option key={1} value={1}>None</Option>
                                        {
                                            layerData.map(it =>
                                                it.cabinet_type_name === "Appliances" &&
                                                <Option key={it.id} value={it.id}>{it.texture_name}</Option>)
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col span={2}>
                                    <Button style={{ width: "100%" }} type="primary"
                                        disabled={this.state.backId === null ? true : false}
                                        onClick={() => this.getDynamicUrlLink()}>Generate</Button>
                                </Col>
                                <Col span={10} offset={1}>
                                    <a href={this.state.dynamic_url} target="blank" style={{ fontSize: 20 }} >{this.state.dynamic_url}</a>
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getMainbackgroundData: Actions.getMainbackgroundData,
        getBrandData: Actions.getBrandData,
        getCatalogData: Actions.getCatalogData,
        getDealerData: Actions.getDealerData,
        getLayerAndMoldingData: Actions.getLayerAndMoldingData
    }, dispatch);
}

function mapStateToProps({ urlgenerator, fuse }) {
    return {
        isLoading: fuse.loading.isLoading,
        urlgenerator: urlgenerator
    }
}
const UrlGeneratorComponent = Form.create()(UrlGenerator);

export default withReducer('urlgenerator', reducer)(withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UrlGeneratorComponent))));