import Brand from './Brand';
import {authRoles} from 'app/auth';

export const BrandConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/brand',
            component: Brand
        }
    ]
};