import Home from './Home';
import {authRoles} from 'app/auth';

export const HomeConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.dealer,
    routes  : [
        {
            path     : '/admin/home',
            component: Home
        }
    ]
};