import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip} from 'antd';

const { Title } = Typography;

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class ProjectLog extends Component {
   
    constructor(props) {
        super(props);
        props.getProjectLog();
    }

    render()
    {
        const { classes, projectlog, isLoading} = this.props;

        const columns = [
            {
                key: 'username',
                title: 'User Name',
                dataIndex: 'username',
                width: 150,
            },
            {
                key: 'project_name',
                title: 'Project Name',
                dataIndex: 'project_name',
                width: 150,
            },
            {
                key: 'count',
                title: 'Load Count',
                dataIndex: 'count',
                width: 150,
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"ProjectLog"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={projectlog}
                                    scroll={{x: 1400, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getProjectLog: Actions.getProjectLog,
    }, dispatch);
}

function mapStateToProps({projectlog, fuse})
{
    return {
        projectlog: projectlog.projectlog.projectlog,
        isLoading:fuse.loading.isLoading
    }
}
const ProjectLogComponent = Form.create()(ProjectLog);

export default withReducer('projectlog', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(ProjectLogComponent))));