import * as Actions from '../actions';

const initialState = {
    types: [],
    catalogCategory: []
};

const typesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_TYPES:
        case Actions.GET_CATALOG_CATEGORY:
        {
            return {
                ...state,
                ...action,
            }
        }
        default:
        {
            return state;
        }
    }
};

export default typesReducer;
