import * as Actions from '../actions';

const initialState = {
    layer: [],
};

const layerReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_LAYER:
        {
            return {
                ...state,
                ...action,
            }   
        }
        default:
        {
            return state;
        }
    }
};

export default layerReducer;
