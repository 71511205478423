import history from '@history';
import jwtService from 'app/services/jwtService';
import jwtServiceDealer from 'app/services/jwtService/jwtServiceDealer';
import {setUserData} from './user.actions';
import {setDealerData} from './dealer.actions';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = "LOGOUT";

export function submitLogin(values)
{
    return (dispatch) =>
        jwtService.signInWithEmailAndPassword(values)
            .then((user) => {
                    dispatch(setUserData(user));

                    return dispatch({
                        type: LOGIN_SUCCESS
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: error
                });
            });
}

export function logout() {
    return (dispatch) => {
        jwtService.logout();
        history.push({
            pathname: '/login',
        });
        console.log("logout1 happeend");
        return dispatch({
            type: LOGOUT,
        })
    }
}

export function login() {
    return (dispatch) => {
        return dispatch({
            type: LOGIN_SUCCESS,
        })
    }
}

export const LOGIN_ERROR_DEALER = 'LOGIN_ERROR_DEALER';
export const LOGIN_SUCCESS_DEALER = 'LOGIN_SUCCESS_DEALER';
export const LOGOUT_DEALER = "LOGOUT_DEALER";
export const RESET_SUCCESS_DEALER = 'RESET_SUCCESS_DEALER';

export function submitLoginDealer(values)
{
    return (dispatch) =>
        jwtServiceDealer.signInWithEmailAndPassword(values)
            .then((dealer) => {
                    dispatch(setDealerData(dealer));

                    return dispatch({
                        type: LOGIN_SUCCESS_DEALER
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : LOGIN_ERROR_DEALER,
                    payload: error
                });
            });
}
export function resetPassword(email)
{
    return (dispatch) =>
        jwtServiceDealer.resetPasswordWithEmail(email)
            .then((user) => {
                    dispatch(setUserData(user));

                    return dispatch({
                        type: RESET_SUCCESS_DEALER
                    });
                }
            )
            .catch(error => {
                return dispatch({
                    type   : LOGIN_ERROR,
                    payload: error
                });
            });
}

export function logoutDealer() {
    return (dispatch) => {
        jwtServiceDealer.logout();
        history.push({
            pathname: '/admin/login',
        });
        return dispatch({
            type: LOGOUT_DEALER,
        })
    }
}

export function loginDealer() {
    return (dispatch) => {
        return dispatch({
            type: LOGIN_SUCCESS_DEALER,
        })
    }
}
