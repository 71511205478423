import Subscription from './Subscription';
import {authRoles} from 'app/auth';

export const SubscriptionConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.dealer,
    routes  : [
        {
            path     : '/admin/subscription',
            component: Subscription
        }
    ]
};