import * as Actions from '../actions';

const initialState = {
    mcategories: [],
    moldingShape: [],
    wallCabinetLayer: [],
};

const mcategoriesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_MCATEGORIES:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_MOLDING_SHAPE:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_WALL_CABINET_LAYER:
        {
            return {
                ...state,
                ...action,
            }   
        }
        default:
        {
            return state;
        }
    }
};

export default mcategoriesReducer;
