import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL } from 'app/main/config';
import {getDealers} from 'app/main/dealer_area/menu/home/store/actions';

export const UPDATE_DEALER = '[DEALER_AREA] UPDATE DEALER CONTENT';
export function updateDealer(data, id, guid, dealer) {
    var newData = {
        ...dealer,
        id, guid,
        catalog_id: [data.catalog_id],
        dealerLogo: null
    }
    
    return (dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/dealer/update`, {
            ...newData
        });

        return request.then((res) =>
        {
            if (!res.data.success) {
                dispatch(showMessage({
                    message         : 'Email already exists',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    }, variant : 'warning'
                }));
                return;
            }
            dispatch(showMessage({
                message         : 'Successfully Updated',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'success'
            }));
            dispatch(getDealers());
        }).catch(err => {
            dispatch(showMessage({
                message         : 'Failed to update dealer data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });
    }
}