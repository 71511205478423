import * as Actions from '../actions';

const initialState = {
    mainbackground: [],
    shapeCategory:[],
    typeCategory:[],
    catalog:[],
};

const mainbackgroundReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_MAIN_BACKGROUND:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_SHAPE_CATEGORY:
        case Actions.GET_TYPE_CATEGORY_MB:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_CATALOG_DATA:
        {
            return {
                ...state,
                ...action,
            }   
        }

        default:
        {
            return state;
        }
    }
};

export default mainbackgroundReducer;
