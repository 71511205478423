import React, { Component } from 'react'
import { beautifyJSON } from 'app/main/config';
import {IconButton} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {Table, Row, Tooltip, Modal, Button} from 'antd';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
});


class ProjectsModal extends Component {
    render() {
        const {open, classes} = this.props;

        const columns = [
            {
                key: 'project_name',
                title: 'Project Name',
                dataIndex: 'project_name',
                width: 150,
                render: (text, record) => (
                    <span>{text}</span>
                ),
            },
            {
                key: 'type_name',
                title: 'Type',
                dataIndex: 'type_name',
                width: 150,
                render: (text, record) => (
                    <span>{text}</span>
                ),
            },
            {
                key: 'saveddate',
                title: 'Saved Date',
                dataIndex: 'saveddate',
                width: 150,
            },
            {
                key: 'name',
                title: 'Background',
                dataIndex: 'name',
                colSpan: 1,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text}</span>
                    </Tooltip>
                )
            },
            {
                key: 'thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            }
        ];
        return (
             <Modal
                zIndex={1300}
                visible={open}
                title="Saved Projects"
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                style={{minWidth: '80%'}}
                footer={[
                    <Button key="submit" type="primary" onClick={this.props.handleOk}>
                        OK
                    </Button>,
                ]}
                >
                <Table 
                    bordered
                    rowKey="projectid"
                    class="mtable"
                    pagination={defaultPagination}
                    columns={columns} 
                    dataSource={this.props.defaultValue}
                    indentSize={20}
                    scroll={{ x: 750, y: 500 }}
                />
            </Modal>

        )
    }
}

export default (withStyles(styles, {withTheme: true})(ProjectsModal));