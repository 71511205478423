import * as Actions from '../actions';

const initialState = {
    content: [],
};

const contentReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        default:
        {
            return state;
        }
    }
};

export default contentReducer;
