import React, {Component} from 'react';
import * as Actions from './store/actions';
import * as HomeActions from 'app/main/dealer_area/menu/home/store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Typography, Spin, Upload, Form, Modal, Row, Col, Button} from 'antd';

var ColorPicker = require('rc-color-picker');
const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};
const showUploadList = {
    showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: false,
    }
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
    titleFont: {
        fontFamily: 'MinionPro-Smbd',
    },
    changeBtnWrapper: {
        width: 100,
        display: 'flex',
    },
    selectBtn: {
        backgroundColor: '#cc5500 !important',
        color: 'white !important',
        width: 100,
    },
    changeBtn: {
        backgroundColor: '#cc5500 !important',
        color: 'white !important',
        width: 100,
        marginRight: 10,
    },
    resizeBtn: {
        backgroundColor: '#666 !important',
        color: 'white !important',
        width: 100,
    },
    upload: {
        border: '1px solid black',
        borderRadius: 70,
        width: 127,
        height: 127,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            borderRadius: 70,
            width: 125,
            height: 125,
        },
        marginBottom: 15,
    },
    color: {
        border: '1px solid black',
        borderRadius: 70,
        width: 127,
        height: 127,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10,
        overflow: 'hidden',
    }
});

class VisualIdentity extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            dealerLogo : [],
            themeColor : '',
            imageSrc   : '',
        };
        props.getDealers();
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }
    handleUploadBtnClick() {
        const file = document.getElementById("upload");
        file && file.click();
    }
    handleUploadAreaChange() {
        const self = this;
        const file = document.getElementById("upload");
        if (file && file.files.length) {
            this.setState({
                dealerLogo: file.files,
            });
            var reader = new FileReader();

            reader.onload = function(event) {
                self.setState({imageSrc: event.target.result});
            }

            reader.readAsDataURL(file.files[0]);
        }
    }

    render()
    {
        const { classes, dealers, user, isLoading} = this.props;
        const { themeColor, dealerLogo, imageSrc } = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;

        const uploadButton = (
            <div>
                <AddIcon />
                <div className="ant-upload-text">
                    Upload
                </div>
            </div>
        );
        const changeButton = (
            <div style={{width: 100, height: 32}}>
                <div className={clsx(classes.changeBtnWrapper)}>
                    <Button className={clsx(classes.changeBtn, "ant-upload-text")}>
                        CHANGE
                    </Button>
                    <Button className={classes.resizeBtn} onClick={ e=>{} }>
                        RESIZE
                    </Button>
                </div>
            </div>
        );

        return (
            <Spin tip="Loading..." spinning={isLoading} wrapperClassName="pt-88">
                <Col span={18}>
                <FusePageCarded
                    style={{height: 900}}
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className={clsx(classes.titleFont, "text-16 sm:text-20 truncate")}>
                                                <div style={{fontSize: 30}}>{"Visual Identity"}</div>
                                            </Title>
                                        </FuseAnimate>
                                        <div style={{fontFamily: 'Trebuchet MS', color: 'black'}}>
                                            Customize your own kitchen visualizer by uploading your logo to the template<br/>
                                            and choosing from our various color schemes
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    }
                    contentToolbar={
                        <Col span={24} style={{display: 'flex', flexDirection: 'row'}}>
                            <Col span={10} style={{display: 'flex', overflow: 'hidden', flexDirection: 'row'}}>
                                <Title level={4} style={{marginLeft: "25px", fontFamily:'AvenirNextLTPro'}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                                    {"Logo"}
                                </Title>
                                <div style={{left: 100, position: 'absolute', width: '1600px', height: 30, backgroundColor: '#cc5500'}}> </div>
                            </Col>
                            <Col span={14} style={{display: 'flex', overflow: 'hidden', flexDirection: 'row'}}>
                                <Title level={4} style={{marginLeft: "25px", fontFamily:'AvenirNextLTPro'}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                                    {"Color Scheme"}
                                </Title>
                                <div style={{left: 190, position: 'absolute', width: '1600px', height: 30, backgroundColor: '#f2ae7e'}}> </div>
                            </Col>
                        </Col>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Col span={10}>
                                    <input type="file" id="upload" style={{display: 'none'}} onChange={this.handleUploadAreaChange.bind(this)} />
                                    <div className={classes.upload}>
                                        { imageSrc && <img id="upload-preview" src={imageSrc} /> }
                                    </div>
                                    { dealerLogo.length ? (
                                    <div>
                                        <Button className={classes.changeBtn} onClick={ ()=>this.handleUploadBtnClick() }>
                                            CHANGE
                                        </Button>
                                        <Button className={classes.resizeBtn} onClick={ ()=>{} }>
                                            RESIZE
                                        </Button>
                                    </div>
                                    ) : (
                                    <Button className={classes.changeBtn} onClick={ ()=>this.handleUploadBtnClick() }>
                                        UPLOAD
                                    </Button> )}
                                    <div style={{paddingTop: 7, fontFamily: 'Trebuchet MS'}}>*supported file types: jpeg, png, gif, pdf</div>
                                </Col>
                                <Col span={14}>
                                    <Col span={8} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <div className={classes.color}>
                                            <div style={{backgroundColor: 'black', width: 63, height: 127}}>  </div>
                                            <div style={{backgroundColor: '#cc5500', width: 63, height: 127}}>  </div>
                                        </div>
                                        <Button className={classes.selectBtn} onClick={ ()=>{} }>
                                            SELECT
                                        </Button>
                                    </Col>
                                    <Col span={8} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <div className={classes.color}>
                                            <div style={{backgroundColor: 'white', width: 63, height: 127}}>  </div>
                                            <div style={{backgroundColor: '#1183b7', width: 63, height: 127}}>  </div>
                                        </div>
                                        <Button className={classes.selectBtn} onClick={ ()=>{} }>
                                            SELECT
                                        </Button>
                                    </Col>
                                    <Col span={8} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <div className={classes.color}>
                                            <div style={{backgroundColor: 'white', width: 63, height: 127}}>  </div>
                                            <div style={{backgroundColor: 'black', width: 63, height: 127}}>  </div>
                                        </div>
                                        <Button className={classes.selectBtn} onClick={ ()=>{} }>
                                            SELECT
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 15}}>
                                <Col span={24}>
                                    <div style={{backgroundColor: 'white', border: '1px solid #AAA', borderBottom: 'none', width: '100%', height: 50}}> 
                                        { imageSrc ? <img src={imageSrc} width="190" height="50" /> : 
                                            <div style={{width: 190, height: 50, display: 'flex', justifyContent: 'center', alignItems:'center'}}>Your Logo</div> }
                                    </div>
                                    <img src="assets/images/front_template.png" />
                                </Col>
                            </Row>
                        </div>
                    }
                />
                </Col>
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getDealers      : HomeActions.getDealers,
        updateDealer    : Actions.updateDealer,
        showMessage     : appActions.showMessage,
        setSortOrder    : HomeActions.setSortOrder,
    }, dispatch);
}

function mapStateToProps({auth, fuse, home})
{
    return {
        isLoading   : fuse.loading.isLoading,
        dealers     : home.home.dealers,
        user        : auth.dealer
    }
}
const VisualIdentityComponent = Form.create()(VisualIdentity);

export default withReducer('visualidentity', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(VisualIdentityComponent))));