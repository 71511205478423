import React, {Component} from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import LoginForm from './LoginForm'
import './style.css'

class Login extends Component {

    state = {
        backgroundImage: 'url(assets/images/logos/1.jpg)',
        fullSize: false,
      }

      componentDidUpdate(prevProps, prevState) {
        if (this.props.login.successDealer === true) {
          this.props.history.push('/admin');
        }
      }
    
      generateBackground = () => {
        let { backgroundImage } = this.state
    
        let min = 1
        let max = 2
        let picNumber = Math.floor(Math.random() * (max - min + 1)) + min
        backgroundImage = 'url(assets/images/logos/' + picNumber + '.jpg)'
        this.setState({
          backgroundImage: backgroundImage,
        })
      }
      render() {
        const { backgroundImage} = this.state
    
        return (
          <div
            className={'dlogin dlogin--fullscreen'}
            style={{ backgroundImage: backgroundImage, backgroundBlendMode: 'color', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
          >
            <div className="dlogin__header">
              <div className="row">
                <div className="col-lg-8">
                  <div className="dlogin__header__logo">
                    {/* <Button className="ml-3" onClick={this.generateBackground}>
                      Randomize Background Image
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="dlogin__block">
              <div className="row">
                <div className="col-xl-12">
                  <div className="dlogin__block__inner">
                    <div className="dlogin__block__form">
                      <div className="logo text-center"> 
                        <img src="assets/images/logos/dealer_logo.png" />
                      </div>
                      <h4 className="text-uppercase text-center text-white" style={{paddingTop: 15}}>
                        <strong> LOG IN TO DEALER AREA </strong>
                      </h4>
                      <LoginForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
}

function mapStateToProps({auth})
{
    return {
        login: auth.login,
      
    }
}

export default withRouter(connect(mapStateToProps)(Login));
