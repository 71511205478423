import * as Actions from '../actions';

const initialState = {
    entities          : [],
    searchText        : '',
    selectedDealerIds : [],
    routeParams       : {},
    catalogCategory   : [],
    dealerDialog      : {
        type : 'new',
        props: {
            open: false
        },
        data : null
    }
};

const dealersReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_DEALERS:
        {
            return {
                ...state,
                entities   : action.payload
            };
        }
        case Actions.SET_SEARCH_TEXT:
        {
            return {
                ...state,
                searchText: action.searchText
            };
        }
        case Actions.TOGGLE_IN_SELECTED_DEALERS:
        {

            const dealerId = action.dealerId;

            let selectedDealerIds = [...state.selectedDealerIds];

            if ( selectedDealerIds.find(id => id === dealerId) !== undefined )
            {
                selectedDealerIds = selectedDealerIds.filter(id => id !== dealerId);
            }
            else
            {
                selectedDealerIds = [...selectedDealerIds, dealerId];
            }

            return {
                ...state,
                selectedDealerIds: selectedDealerIds
            };
        }
        case Actions.SELECT_ALL_DEALERS:
        {
            const arr = Object.keys(state.entities).map(k => state.entities[k]);

            const selectedDealerIds = arr.map(dealer => dealer.id);

            return {
                ...state,
                selectedDealerIds: selectedDealerIds
            };
        }
        case Actions.DESELECT_ALL_DEALERS:
        {
            return {
                ...state,
                selectedDealerIds: []
            };
        }
        case Actions.OPEN_NEW_DEALER_DIALOG:
        {
            return {
                ...state,
                dealerDialog: {
                    type : 'new',
                    props: {
                        open: true
                    },
                    data : null
                }
            };
        }
        case Actions.CLOSE_NEW_DEALER_DIALOG:
        {
            return {
                ...state,
                dealerDialog: {
                    type : 'new',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.OPEN_EDIT_DEALER_DIALOG:
        {
            return {
                ...state,
                dealerDialog: {
                    type : 'edit',
                    props: {
                        open: true
                    },
                    data : action.data
                }
            };
        }
        case Actions.CLOSE_EDIT_DEALER_DIALOG:
        {
            return {
                ...state,
                dealerDialog: {
                    type : 'edit',
                    props: {
                        open: false
                    },
                    data : null
                }
            };
        }
        case Actions.GET_CATALOG_CATEGORY:
        {
            return {
                ...state,
                catalogCategory: action.catalogCategory
            }
        }
        default:
        {
            return state;
        }
    }
};

export default dealersReducer;
