import React from 'react';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import isDealerArea from '../../../validateDealer';

const useStyles = makeStyles(theme => ({
    root      : {
        '& .logo-icon'                : {
            width     : 24,
            height    : 24,
            transition: theme.transitions.create(['width', 'height'], {
                duration: theme.transitions.duration.shortest,
                easing  : theme.transitions.easing.easeInOut
            }),
        },
        '& .react-badge, & .logo-text': {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
                easing  : theme.transitions.easing.easeInOut
            })
        },
        '& .dealer-logo': {
            width: 160,
            height: 40,
        }
    },
    reactBadge: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color          : '#61DAFB'
    }
}));

function Logo()
{
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, "flex items-center pl-10")}>
            <img className={isDealerArea ? "dealer-logo" : "logo-icon"} src={`assets/images/logos/${isDealerArea?'dealer_logo.png':'fuse.svg'}`} alt="logo"/>
            { !isDealerArea &&
            <Typography className={`text-18 ml-12 font-light logo-text ${isDealerArea ? '':'card-title'}`} color="textPrimary">
                <strong> { isDealerArea ? 'LeadGEN' : 'Kitchen' } Visualizer </strong>
            </Typography> }
        </div>
    );
}

export default Logo;
