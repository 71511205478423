import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL } from 'app/main/config';

export const GET_PROJECT_LOG = '[PROJECT] GET PROJECT LOG';
export function getProjectLog() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/project/read/projectlog`)
        .then(response => {
            const {projectlog} = response.data;
                dispatch({
                    type: GET_PROJECT_LOG,
                    projectlog: projectlog,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load LOG data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });
    })
}
