import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip} from 'antd';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Brand extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            visible : false,
            type    : "new",
            id      : null,
            guid    : null,
            name    : '',
            theme   : '',
            logo    : [],
        };
        props.getBrand();       
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                type            : type,
                id              : null,
                guid            : null,
                name            : '',
                logo            : [],
                theme           : '',
            })
        }
        else if(type === "edit") {
            this.setState({
                type        : type,
                id          : record.id,
                guid        : record.guid,
                name        : record.name,
                logo: [{
                    uid     : 1,
                    name    : record.logo.split('/').pop(),
                    status  : 'done',
                    url     : record.logo
                }],
                theme       : record.theme,
            })
        }
        this.setState({
          visible: open,
        });
    };

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              this.setState({visible: false});
                if(type === "new") {
                    this.props.addBrand(values);
                }
                if(type === "edit" && id !== null) {
                    this.props.updateBrand(values, id, this.state.guid);
                }                
            }
        });
    }

    handleDeleteEvent = (record) => {
        const {deleteBrand} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteBrand(record)
            },
            onCancel() {},
        });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {brand} = this.props;
        const index = brand.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== brand.length - 1) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: brand[index].id, sort_order: brand[swapIndex].sort_order});
            this.props.setSortOrder({id: brand[swapIndex].id, sort_order: brand[index].sort_order});
        }
    }


    render()
    {
        const { classes, brand, isLoading } = this.props;
        const { name, logo, theme, visible, type } = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );

        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 200,
            },
            {
                key: 'theme',
                title: 'Theme',
                dataIndex: 'theme',
                width: 200,
            },
            {
                key: 'logo',
                title: 'Logo',
                dataIndex: 'logo',
                colSpan: 2,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'logo',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        
        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Brand"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            name  : this.state.name,
                                            theme     : this.state.theme,
                                            logo    : this.state.logo,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Brand
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="name"
                                                        {...formItemLayout}
                                                        label="Brand Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Brand.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Brand Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="theme"
                                                        {...formItemLayout}
                                                        label="Theme Name"
                                                    >
                                                        {getFieldDecorator("theme", {
                                                            initialValue: theme,
                                                            rules: [ {
                                                                required: true, message: 'Enter Theme.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Theme Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="logo"
                                                        {...formItemLayout}
                                                        label="Logo"
                                                    >
                                                        {getFieldDecorator("logo", {
                                                            initialValue: logo,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please select logo image!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        logo: [],
                                                                    });
                                                                }}
                                                                beforeUpload={file => {
                                                                    this.setState({
                                                                        logo: [file],
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {logo.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={brand}
                                    scroll={{x: 1500, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getBrand: Actions.getBrand,
        addBrand: Actions.addBrand,
        deleteBrand: Actions.deleteBrand,
        updateBrand: Actions.updateBrand,
        showMessage     : appActions.showMessage,
        setSortOrder: Actions.setSortOrder,

    }, dispatch);
}

function mapStateToProps({brand, fuse})
{
    return {
        brand: brand.brand.brand,
        isLoading:fuse.loading.isLoading
    }
}
const BrandComponent = Form.create()(Brand);

export default withReducer('brand', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(BrandComponent))));