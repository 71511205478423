import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import { withStyles } from '@material-ui/core/styles';
import {Typography, Spin, Form, Row, Col} from 'antd';
import LeadsComponent from '../leads/LeadsComponent';
import LineChart from './Analytics/LineChart';
import PieChart from './Analytics/PieChart';

const { Title } = Typography;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Home extends Component {
   
    constructor(props) {
        super(props); 
    }

    render()
    {
        const { classes, isLoading } = this.props;

        return (
            <Spin tip="Loading..." spinning={isLoading} wrapperClassName="pt-88">
                <Row>
                    <Col span={12}>
                        <LeadsComponent headerTitle="Welcome to the Dealer Area" />
                    </Col>
                    <Col span={12}>
                        <FusePageCarded
                            style={{height: 600}}
                            classes={{
                                toolbar: "p-0",
                                header : "min-h-72 h-72 sm:h-96 sm:min-h-96"
                            }}
                            contentToolbar={
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Title level={4} style={{marginLeft: "25px", fontFamily:'AvenirNextLTPro'}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                                        {"Analytics"}
                                    </Title>
                                    <div style={{marginLeft: 20, width: '1600px', height: 30, backgroundColor: '#f2ae7e'}}> </div>
                                </div>
                            }
                            content={
                                <div className="p-24">
                                    <Row>
                                        <LineChart />
                                    </Row>
                                    <Row>
                                        <Col span={14}>
                                            <LineChart />
                                        </Col>
                                        <Col span={8}>
                                            <PieChart />
                                        </Col>
                                    </Row>
                                </div>
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={7}>
                        <FusePageCarded
                            style={{height: 300}}
                            classes={{
                                toolbar: "p-0",
                                header : "min-h-72 h-72 sm:h-24 sm:min-h-24"
                            }}
                            contentToolbar={
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <Title level={4} style={{marginLeft: "25px", fontFamily:'AvenirNextLTPro'}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                                        {"News & Updates"}
                                    </Title>
                                    <div style={{marginLeft: 20, width: '1600px', height: 30, backgroundColor: '#666666'}}> </div>
                                </div>
                            }
                            content={
                                <div className="p-24">
                                </div>
                            }
                        />
                    </Col>
                </Row>
                
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({fuse})
{
    return {
        isLoading:fuse.loading.isLoading
    }
}
const HomeComponent = Form.create()(Home);

export default withReducer('home', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(HomeComponent))));