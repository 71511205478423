import React, {Component} from 'react';
import * as Actions from './store/actions';
import * as HomeActions from 'app/main/dealer_area/menu/home/store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip} from 'antd';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Subscription extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            companyName     : '',
            contactPerson   : '',
            address         : '',
            telephone       : '',
            visualizerName  : '',
            email           : '',
            url             : '',
            visible         : false,
            type            : "new",
            id              : null,
        };
        props.getDealers();       
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                type            : type,
                id              : null,
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                guid        : record.guid,
                companyName : record.companyName,
                contactPerson: record.contactPerson,
                address     : record.address,
                telephone   :record.telephone,
                visualizerName: record.visualizerName,
                email       : record.email,
                url         : record.url,
                type            : type,
            })
        }
        this.setState({
          visible: open,
        });
    };


    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id, guid} = this.state;
              this.setState({visible: false});
                if(type === "edit" && id !== null) {
                    const dealer = this.props.dealers.filter(element => { return element.id === id })[0];
                    this.props.updateDealer(values, id, guid, dealer);
                }                
            }
        });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {dealers} = this.props;
        const index = dealers.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && dealers[index-1].id === record.id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== dealers.length - 1 && dealers[index+1].id === record.id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: dealers[index].id, sort_order: dealers[swapIndex].sort_order});
            this.props.setSortOrder({id: dealers[swapIndex].id, sort_order: dealers[index].sort_order});
        }
    }

    render()
    {
        const { classes, dealers, user, isLoading} = this.props;
        const { companyName, contactPerson, address, telephone, visualizerName, email, url, visible, type} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );

        const columns = [
            {
                key: 'companyName',
                title: 'Company Name',
                dataIndex: 'companyName',
                width: 200,
            },
            {
                key: 'contactPerson',
                title: 'Contact Person',
                dataIndex: 'contactPerson',
                width: 200,
            },
            {
                key: 'address',
                title: 'Address',
                dataIndex: 'address',
                width: 200,
            },
            {
                key: 'telephone',
                title: 'Telephone',
                dataIndex: 'telephone',
                width: 200,
            },
            {
                key: 'visualizerName',
                title: 'Visualizer Name',
                dataIndex: 'visualizerName',
                width: 200,
            },
            {
                key: 'email',
                title: 'Email',
                dataIndex: 'email',
                width: 200,
            },
            {
                key: 'url',
                title: 'Website Link',
                dataIndex: 'url',
                width: 200,
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Your Subscription Info"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            companyName,
                                            contactPerson,
                                            address,
                                            telephone,
                                            visualizerName,
                                            email,
                                            url
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Subscription
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="companyName"
                                                        {...formItemLayout}
                                                        label="Company Name"
                                                    >
                                                        {getFieldDecorator("companyName", {
                                                            initialValue: companyName,
                                                            rules: [ {
                                                                required: true, message: 'Enter Company Name.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Company Name"
                                                                onChange={(e)=> {
                                                                    this.setState({companyName: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="contactPerson"
                                                        {...formItemLayout}
                                                        label="Contact Person"
                                                    >
                                                        {getFieldDecorator("contactPerson", {
                                                            initialValue: contactPerson,
                                                            rules: [ {
                                                                required: true, message: 'Enter Contact.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Contact"
                                                                onChange={(e)=> {
                                                                    this.setState({contactPerson: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="address"
                                                        {...formItemLayout}
                                                        label="Address"
                                                    >
                                                        {getFieldDecorator("address", {
                                                            initialValue: address,
                                                            rules: [ {
                                                                required: true, message: 'Enter Address.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Address"
                                                                onChange={(e)=> {
                                                                    this.setState({address: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="telephone"
                                                        {...formItemLayout}
                                                        label="Telephone"
                                                    >
                                                        {getFieldDecorator("telephone", {
                                                            initialValue: telephone,
                                                            rules: [ {
                                                                required: true, message: 'Enter Telephone.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Telephone"
                                                                onChange={(e)=> {
                                                                    this.setState({telephone: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="visualizerName"
                                                        {...formItemLayout}
                                                        label="Visualizer Name"
                                                    >
                                                        {getFieldDecorator("visualizerName", {
                                                            initialValue: visualizerName,
                                                            rules: [ {
                                                                required: true, message: 'Enter Visualizer Name.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Visualizer Name"
                                                                onChange={(e)=> {
                                                                    this.setState({visualizerName: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="email"
                                                        {...formItemLayout}
                                                        label="Email"
                                                    >
                                                        {getFieldDecorator("email", {
                                                            initialValue: email,
                                                            rules: [ {
                                                                required: true, message: 'Enter Email.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Email"
                                                                onChange={(e)=> {
                                                                    this.setState({email: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="url"
                                                        {...formItemLayout}
                                                        label="Website Link"
                                                    >
                                                        {getFieldDecorator("url", {
                                                            initialValue: url,
                                                            rules: [ {
                                                                required: true, message: 'Enter link url.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter link url"
                                                                onChange={(e)=> {
                                                                    this.setState({url: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={dealers && dealers.filter(element => element.email === user.email )}
                                    scroll={{x: 1400, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getDealers      : HomeActions.getDealers,
        updateDealer     : Actions.updateDealer,
        showMessage     : appActions.showMessage,
        setSortOrder    : HomeActions.setSortOrder,
    }, dispatch);
}

function mapStateToProps({auth, fuse, home})
{
    return {
        isLoading   : fuse.loading.isLoading,
        dealers     : home.home.dealers,
        user        : auth.dealer
    }
}
const SubscriptionComponent = Form.create()(Subscription);

export default withReducer('subscription', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(SubscriptionComponent))));