import Content from './Content';
import {authRoles} from 'app/auth';

export const ContentConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.dealer,
    routes  : [
        {
            path     : '/admin/content',
            component: Content
        }
    ]
};