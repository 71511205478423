import * as Actions from '../actions';

const initialState = {
    shapes: [],
    typeCategory: [],
    catalog: [],
};

const shapesReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_SHAPES:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_TYPE_CATEGORY:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_CATALOG_DATA:
        {
            return {
                ...state,
                ...action,
            }   
        }
        default:
        {
            return state;
        }
    }
};

export default shapesReducer;
