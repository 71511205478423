import * as Actions from '../actions';
import { GET_BRAND_DATA, GET_CATALOG_DATA, GET_DEALER_DATA, GET_LAYER_AND_MOLDING_DATA, GET_MAINBACKGROUND_DATA } from '../actions';

const initialState = {
    mainbackground: [],
    layerData: [],
    brand: [],
    catalog: [],
    dealer: [],
    moldingData: [],
};

const urlgeneratorReducer = function (state = initialState, action) {
    switch ( action.type )
    {

        case GET_MAINBACKGROUND_DATA:
        case GET_LAYER_AND_MOLDING_DATA:
        case GET_BRAND_DATA:
        case GET_CATALOG_DATA:
        case GET_DEALER_DATA:
            return {...state, ...action.payload};

        default:
        {
            return state;
        }
    }
};

export default urlgeneratorReducer;
