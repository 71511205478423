import * as Actions from '../actions';

const initialState = {
    home: [],
    dealers: [],
    catalogCategory: [],
};

const homeReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_DEALERS:
        {
            return {
                ...state,
                dealers: action.payload,
            }
        }
        case Actions.GET_CATALOG_CATEGORY:
        {
            return {
                ...state,
                catalogCategory: action.payload,
            }
        }
        default:
        {
            return state;
        }
    }
};

export default homeReducer;
