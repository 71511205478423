import MoldingShape from './MoldingShape';
import {authRoles} from 'app/auth';
export const MoldingShapeConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/molding/shape',
            component: MoldingShape
        }
    ]
};