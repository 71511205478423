import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_BRAND = '[BRAND] GET BRAND';
export function getBrand() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/brand/read/brand`)
        .then(response => {
            const {brand} = response.data;
                dispatch({
                    type:GET_BRAND,
                    brand: brand,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load BRAND data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });        
    })
}

export const ADD_BRAND = '[BRAND] ADD BRAND';
export function addBrand(data) {
    var newData = {
        name: data.name,
        theme: data.theme,
        logo: data.logo[0].name,
    }
    const formData = new FormData();
    formData.append("file", data.logo[0].originFileObj);
    
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/brand/insert/brand`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.logo.filter(file => file.originFileObj !== undefined), `uploads/brand/${res.data.brand.guid + '_' +res.data.brand.id}/`).then(logo => {
                    const uploadData = [ 
                        {
                            fieldName: 'logo',
                            type: 'single',
                            data: logo
                        }
                    ];
                    axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                        {
                            id: res.data.brand.id,
                            tableName: "brand",
                            data: uploadData
                        }
                    )
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            dispatch(showMessage({
                                message         : 'Successfully Inserted',
                                autoHideDuration: 2000,
                                anchorOrigin    : {
                                    vertical  : 'top',
                                    horizontal: 'right'
                                },variant : 'success'
                            }));
                            Promise.all([
                                dispatch({
                                    type: ADD_BRAND
                                })
                            ]).then(() => dispatch(getBrand()))
                        } else {
                            console.log(success);
                        }
                    })
                })
                .catch(err => {
                    console.log("failed to upload brand logo. ", err);
                    return;
                })
            } else {
                formData.append("guid" , res.data.brand.guid);
                formData.append("id" , res.data.brand.id);
                axios.post(`${API_SERVER_URL}/api/brand/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_BRAND
                                    })
                                ]).then(() => dispatch(getBrand()))
                            }
                        })
                    }
                }).catch(err => {
                    dispatch(showMessage({
                        message         : 'Failed to add BRAND data',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    }));
        
                    console.log("err", err);
                });        
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });    
    })
}

export const DELETE_BRAND = '[BRAND] DELETE BRAND';
export function deleteBrand(record) {
    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/brand/remove/brand`, {
                id: record.id
            })
            .then(response => {
                const {success} = response.data;
                if(success === true) {
                    var p1 = new Promise((resolve, reject) => {
                        dispatch(uploadLoading(true));
                        if(process.env.REACT_APP_MODE === "production" ) {
                            const data = [];
                            data.push(record.logo);
                            deleteFiles(data).then(res => {
                                resolve("success");
                            })
                            .catch(err => {
                                console.log(err);
                                reject(err);
                            })
                        } else {
                            resolve("success");
                        }
                    })
                    p1.then(p1Value => {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message         : 'Successfully Deleted',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'success'
                        }));
                    
                        Promise.all([
                            dispatch({
                                type: DELETE_BRAND
                            })
                        ]).then(() => dispatch(getBrand()))
                    }).catch (err => {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message: 'AWS S3 DELETE BRAND ERROR',
                            autoHideDuration: 4000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                        return;
                    })
                }else{
                    dispatch(showMessage({
                        message: 'Some Door Colors are using this style',
                        autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'warning'
                    }));
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete BRAND data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
                console.log("err", err);
            });        
      
        
    })
}

export const UPDATE_BRAND = '[BRAND] UPDATE BRAND';
export function updateBrand(data, id, guid) {
    var updateData = {
        id          : id,
        theme       : data.theme,
        name        : data.name,
        logo        : jsonArray([data.logo[0]]),
    }

    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/brand/update/brand`, {
                ...updateData
            }).then(res => {
                dispatch(uploadLoading(true));
                if(process.env.REACT_APP_MODE === "production") {
                    putFiles(data.logo.filter(file => file.originFileObj !== undefined), `uploads/brand/${guid + '_' + id}/`).then(logo => {                        
                        const uploadData = [
                            {
                                fieldName: 'logo',
                                type: 'single',
                                data: logo
                            }
                        ];

                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: id,
                                tableName: "brand",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: UPDATE_BRAND
                                    })
                                ]).then(() => dispatch(getBrand()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to put shape style thumbnail. ", err);
                        return;
                    })
                } else {
                    if(data.logo[0].originFileObj !== undefined) {
                        const formData = new FormData();
                        formData.append("file", data.logo[0].originFileObj);
                        formData.append("guid" , guid);
                        formData.append("id" , id);
                        axios.post(`${API_SERVER_URL}/api/brand/upload`, formData)
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                axios.post(`${API_SERVER_URL}/uploads/clear`)
                                .then(res => {
                                    if(res.data.success === true ){
                                        dispatch(showMessage({
                                            message         : 'Successfully Updated',
                                            autoHideDuration: 2000,
                                            anchorOrigin    : {
                                                vertical  : 'top',
                                                horizontal: 'right'
                                            },variant : 'success'
                                        }));
                                        Promise.all([
                                            dispatch({
                                                type: UPDATE_BRAND
                                            })
                                        ]).then(() => dispatch(getBrand()))
                                    }
                                })
                            }
                        })
                    }
                    else {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message         : 'Successfully Updated',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'success'
                        }));
                        Promise.all([
                            dispatch({
                                type: UPDATE_BRAND
                            })
                        ]).then(() => dispatch(getBrand()))
                    }
                }
            }).catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to update BRAND data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
    
                console.log("err", err);
            });    
    })
}

export const SET_BRAND_SORT_ORDER = '[BRAND] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'brand'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_BRAND_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getBrand()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in brand style.", err);
        })
    })
}