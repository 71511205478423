import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_TYPES = '[TYPES] GET TYPES';
export function getTypes() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/types/read/types`)
        .then(response => {
            const {types} = response.data;
                dispatch({
                    type:GET_TYPES,
                    types: types,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load TYPES data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });        
    })
}

export const GET_CATALOG_CATEGORY = '[CATALOG] GET CATALOG CATEGORY';
export function getCatalogCategory() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/catalog/read/catalog`)
        .then(response => {
            const {catalog, success} = response.data;
            if(success === true) {     
                dispatch({
                    type: GET_CATALOG_CATEGORY,
                    catalogCategory: catalog,
                })
            }else {
                Promise.all([
                    dispatch({
                        type: GET_TYPES
                    })
                ]).then(() => dispatch(getTypes()))
            }
        })    
    })
}

export const ADD_TYPES = '[TYPES] ADD TYPES';
export function addTypes(data) {
    var newData = {
        name: data.name,
        thumbnail: data.thumbnail[0].name,
        isdirect : data.isdirect,
        is360 : data.is360,
        catalog_id: data.catalog_id,
    }
    const formData = new FormData();
    formData.append("file", data.thumbnail[0].originFileObj);
    
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/types/insert/types`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/types/${res.data.types.guid + '_' +res.data.types.id}/`).then(thumbnail => {                        
                    const uploadData = [ 
                        {
                            fieldName: 'thumbnail',
                            type: 'single',
                            data: thumbnail,
                            isdirect: data.isdirect,
                            is360: data.is360,
                            name: data.name,
                        }
                    ];
                    axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                        {
                            id: res.data.types.id,
                            tableName: "types",
                            data: uploadData
                        }
                    )
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            dispatch(showMessage({
                                message         : 'Successfully Inserted',
                                autoHideDuration: 2000,
                                anchorOrigin    : {
                                    vertical  : 'top',
                                    horizontal: 'right'
                                },variant : 'success'
                            }));
                            Promise.all([
                                dispatch({
                                    type: ADD_TYPES
                                })
                            ]).then(() => dispatch(getTypes()))
                        } else {
                            console.log(success);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        dispatch(uploadLoading(false));
                    });
                })
                .catch(err => {
                    console.log("failed to upload types thumbnail. ", err);
                    return;
                })
            } else {
                console.log("res.data", res.data);
                formData.append("guid" , res.data.types.guid);
                formData.append("id" , res.data.types.id);
                formData.append("name" , res.data.types.name);
                formData.append("thumbnail", res.data.types.thumbnail);
                formData.append("isdirect", res.data.types.isdirect);
                formData.append("is360", res.data.types.is360);
                axios.post(`${API_SERVER_URL}/api/types/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    console.log("----dsfdsfdsf", response.data);
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_TYPES
                                    })
                                ]).then(() => dispatch(getTypes()))
                            }
                        })
                    }
                }).catch(err => {
                    dispatch(showMessage({
                        message         : 'Failed to add TYPES data',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    }));
        
                    console.log("err", err);
                });        
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });    
    })
}

export const DELETE_TYPES = '[TYPES] DELETE TYPES';
export function deleteTypes(record) {
    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/types/remove/types`, {
                id: record.id,
                isdirect: record.isdirect,
                is360 : record.is360,
            })
            .then(response => {
                const {success} = response.data;
                if(success === true) {
                    var p1 = new Promise((resolve, reject) => {
                        dispatch(uploadLoading(true));
                        if(process.env.REACT_APP_MODE === "production" ) {
                            const data = [];
                            data.push(record.thumbnail);
                            deleteFiles(data).then(res => {
                                resolve("success");
                            })
                            .catch(err => {
                                console.log(err);
                                reject(err);
                            })
                        } else {
                            resolve("success");
                        }
                    })
                    p1.then(p1Value => {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message         : 'Successfully Deleted',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'success'
                        }));
                    
                        Promise.all([
                            dispatch({
                                type: DELETE_TYPES
                            })
                        ]).then(() => dispatch(getTypes()))
                    }).catch (err => {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message: 'AWS S3 DELETE TYPES ERROR',
                            autoHideDuration: 4000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                        return;
                    })
                }else{
                    dispatch(showMessage({
                        message: 'Some Door Colors are using this style',
                        autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'warning'
                    }));
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete TYPES data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
                console.log("err", err);
            });        
      
        
    })
}

export const UPDATE_TYPES = '[TYPES] UPDATE TYPES';
export function updateTypes(data, id, guid) {
    var updateData = {
        id                      : id,
        name                    : data.name,
        thumbnail               : jsonArray([data.thumbnail[0]]),
        isdirect                : data.isdirect,
        is360                   : data.is360,
        catalog_id              : data.catalog_id,
    }

    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/types/update/types`, {
                ...updateData
            }).then(res => {
                dispatch(uploadLoading(true));
                if(process.env.REACT_APP_MODE === "production") {
                    putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/types/${guid + '_' + id}/`).then(thumbnail => {                        
                        const uploadData = [
                            {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail,
                                isdirect: data.isdirect,
                                is360 : data.is360,
                                name: data.name,
                            }
                        ];

                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: id,
                                tableName: "types",
                                data: uploadData,
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: UPDATE_TYPES
                                    })
                                ]).then(() => dispatch(getTypes()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to put type thumbnail. ", err);
                        return;
                    })
                } else {
                    if(data.thumbnail[0].originFileObj !== undefined) {
                        const formData = new FormData();
                        formData.append("file", data.thumbnail[0].originFileObj);
                        formData.append("guid" , guid);
                        formData.append("id" , id);
                        formData.append("name" , data.name);
                        formData.append("isdirect" , data.isdirect);
                        formData.append("is360" , data.is360);
                        axios.post(`${API_SERVER_URL}/api/types/upload`, formData)
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                axios.post(`${API_SERVER_URL}/uploads/clear`)
                                .then(res => {
                                    if(res.data.success === true ){
                                        dispatch(showMessage({
                                            message         : 'Successfully Updated',
                                            autoHideDuration: 2000,
                                            anchorOrigin    : {
                                                vertical  : 'top',
                                                horizontal: 'right'
                                            },variant : 'success'
                                        }));
                                        Promise.all([
                                            dispatch({
                                                type: UPDATE_TYPES
                                            })
                                        ]).then(() => dispatch(getTypes()))
                                    }
                                })
                            }
                        })
                    }
                    else {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message         : 'Successfully Updated',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'success'
                        }));
                        Promise.all([
                            dispatch({
                                type: UPDATE_TYPES
                            })
                        ]).then(() => dispatch(getTypes()))
                    }
                }
            }).catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to update TYPES data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
    
                console.log("err", err);
            });    
    })
}

export const SET_TYPES_SORT_ORDER = '[TYPES] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'types'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_TYPES_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getTypes()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in door style.", err);
        })
    })
}