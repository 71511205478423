import React from 'react';
import {Icon, IconButton} from '@material-ui/core';
import * as Actions from 'app/store/actions';
import {useDispatch} from 'react-redux';
import isDealerArea from '../../../validateDealer';

function NavbarMobileToggleButton(props)
{
    const dispatch = useDispatch();

    return (
        <IconButton className={props.className} onClick={ev => dispatch(Actions.navbarToggleMobile())} color="inherit" disableRipple style={isDealerArea ? {color: 'black'} : {}}>
            {props.children}
        </IconButton>
    );
}

NavbarMobileToggleButton.defaultProps = {
    children: <Icon>menu</Icon>
};

export default NavbarMobileToggleButton;
