import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_CATALOG = '[CATALOG] GET CATALOG';
export function getCatalog() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/catalog/read/catalog`)
        .then(response => {
            const {catalog} = response.data;
                dispatch({
                    type:GET_CATALOG,
                    catalog: catalog,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load CATALOG data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });        
    })
}

export const GET_BRAND_CATEGORY = '[CATALOG] GET BRAND CATEGORY';
export function getBrandCategory() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/catalog/read/brand`)
        .then(response => {
            const {brand, success} = response.data;
            if(success === true) {                
                dispatch({
                    type:GET_BRAND_CATEGORY,
                    brandCategory: brand,
                })
            }else {
                Promise.all([
                    dispatch({
                        type: GET_CATALOG
                    })
                ]).then(() => dispatch(getCatalog()))
            }
        })    
    })
}


export const ADD_CATALOG = '[CATALOG] ADD CATALOG';
export function addCatalog(data) {
    var newData = {
        brand_id: data.brand_id,
        name: data.name,
        basic_catalog_id: data.basic_catalog_id,
    }
    const formData = new FormData();
    
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/catalog/insert/catalog`, {
            ...newData
        })
        .then(res => {
            const {success} = res.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Inserted',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                Promise.all([
                    dispatch({
                        type: ADD_CATALOG
                    })
                ]).then(() => dispatch(getCatalog()))
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });    
    })
}

export const DELETE_CATALOG = '[CATALOG] DELETE CATALOG';
export function deleteCatalog(record) {
    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/catalog/remove/catalog`, {
                id: record.id
            })
            .then(res => {
                const {success} = res.data;
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: DELETE_CATALOG
                        })
                    ]).then(() => dispatch(getCatalog()))
                }
            })
            .catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to upload data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));

                console.log("err", err);
            });
        })
}

export const UPDATE_CATALOG = '[CATALOG] UPDATE CATALOG';
export function updateCatalog(data, id) {
    var updateData = {
        id                      : id,
        brand_id                : data.brand_id,
        name                    : data.name,
        basic_catalog_id        : data.basic_catalog_id,
    }

    return ((dispatch) => {
            axios.post(`${API_SERVER_URL}/api/catalog/update/catalog`, {
                ...updateData
            }).then(res => {
                const {success} = res.data;
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: UPDATE_CATALOG
                        })
                    ]).then(() => dispatch(getCatalog()))
                }
            })
            .catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to upload data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));

                console.log("err", err);
            });
        })
}

export const SET_CATALOG_SORT_ORDER = '[CATALOG] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'catalog'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_CATALOG_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getCatalog()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in shape style.", err);
        })
    })
}