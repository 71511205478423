import DealersApp from './DealersApp';
import {authRoles} from 'app/auth';

export const DealersAppConfig = {
    settings: {
        layout: {
        }
    },
    auth: authRoles.superDesigner,
    routes  : [
        {
            path     : '/dealer',
            component: DealersApp
        }
    ]
};
