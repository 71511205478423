import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {FormControl, MenuItem , OutlinedInput, Select, Fab, InputLabel, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {HOTSPOTS_TYPES} from '../config';

const styles = theme =>({
    button: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl : {
        width: '100%',
        margin: theme.spacing(1),
    }
});


class HotspotAddDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            nav_mainbg: -1,
            rotateOnSelect: 0
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.value.type !== prevProps.value.type) {
            this.setState({type: this.props.value.type})
        }
        if(this.props.value.nav_mainbg !== prevProps.value.nav_mainbg) {
            this.setState({nav_mainbg: this.props.value.nav_mainbg})
        }
        if(!prevProps.open && this.props.open) {
            this.setState({rotateOnSelect: this.props.value.rotateOnSelect});
        }
    }
    
    handleSaveBtn = () => {
        const { onClose, hotspots, deleteHotspot, value: { id } } = this.props;
        const {x, y} = this.props.value;
        const {type, nav_mainbg, rotateOnSelect} = this.state;

        let item = hotspots.filter(item => item.type === "LookAt");
        if ((id === null) && (item.length !== 0) && type === "LookAt") {
            deleteHotspot(item[0].id);
        }
        
        onClose({type, nav_mainbg, mode:"save", x, y, rotateOnSelect});
    }

    handleDeleteBtn = () => {
        const {onClose} = this.props;
        const {type, nav_mainbg} = this.state;
        onClose({type, nav_mainbg, mode:"delete"})
    }

    handleClose = () => {
        const {onClose} = this.props;
        onClose(null);
    }

    handleChange = (evt) => {
        this.setState({type: evt.target.value});
    }

    handleChangeMainBG = (evt) => {
        this.setState({nav_mainbg: evt.target.value});
    }

    render() {
        const {classes, value, open, mainbackgrounds, hotspots} = this.props;
        const {id, x, y} = value;
        const {type, nav_mainbg, rotateOnSelect} = this.state;

        const title = (id == null ? "Add Hotspot" : "Edit Hotspot");
        let availableTypes = HOTSPOTS_TYPES;

        let showMainBG = (type == "Navigation");
        
        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <div className={classes.container}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="component-outlined1">
                                X
                            </InputLabel>
                            <OutlinedInput
                                disabled
                                id="component-outlined1"
                                defaultValue={x.toFixed(2)}
                                labelWidth={30}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="component-outlined2">
                                Y
                            </InputLabel>
                            <OutlinedInput
                                disabled
                                id="component-outlined2"
                                defaultValue={y.toFixed(2)}
                                labelWidth={30}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-age-simple">
                                Type
                            </InputLabel>
                            <Select
                                value={type}
                                onChange={this.handleChange}
                                input={<OutlinedInput labelWidth={30} name="age" id="outlined-age-simple" />}
                            >
                                {availableTypes.map(el => (<MenuItem key={el.value} value={el.value}>{el.text}</MenuItem>))}
                            </Select>
                        </FormControl>
                        {(id === null) && (type === "LookAt") && (hotspots.filter(item => item.type === "LookAt").length !== 0) &&
                            <span style={{ color: "#f33", paddingLeft: 10 }}>Look At hotspot already exists. Save to update the position.</span>
                        }
                        {(type !== "LookAt" && type !== "Navigation") && <FormControl variant="outlined" className={classes.formControl}>
                            <Checkbox checked={!!rotateOnSelect} onChange={v => this.setState({ rotateOnSelect: Number(v.target.checked) })}/>
                            <InputLabel htmlFor="outlined-age-simple">
                                Rotate On Select
                            </InputLabel>
                        </FormControl>}
                        {showMainBG && <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="outlined-age-simple">
                                Main Background
                            </InputLabel>
                            <Select
                                value={nav_mainbg}
                                onChange={this.handleChangeMainBG}
                                input={<OutlinedInput labelWidth={120} name="age" id="outlined-age-simple" />}
                            >
                                {mainbackgrounds.map(el => (<MenuItem key={el.id} value={el.id}>{el.main_background_name}</MenuItem>))}
                            </Select>
                        </FormControl>}
                    </div>
                </DialogContent>
                <DialogActions>
                    { id != null && 
                    (<Fab variant="extended" color="secondary"  className={classes.button} onClick={this.handleDeleteBtn}>
                        <DeleteIcon/>
                        Delete
                    </Fab>)
                    }
                    <Fab variant="extended" color="primary"  className={classes.button} disabled={type === "" ? true : false} onClick={this.handleSaveBtn}>
                        <SaveIcon/>
                        Save
                    </Fab>
                </DialogActions>
            </Dialog>
        );
    }
}

export default (withStyles(styles, {withTheme: true})((HotspotAddDialog)));
