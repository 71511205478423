import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Select, Input, Spin, Upload, Form, Modal, Row, Col, Tooltip, Icon, Button as AntDButton} from 'antd';
import Highlighter from 'react-highlight-words';

const { Title } = Typography;
const {Option} = Select;
const { confirm } = Modal;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Mcategories extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            molding_shape_id: undefined,
            wall_cabinet_layer_ids : undefined,
            visible         : false,
            type            : "new",
            id              : null,
            searchFilter    : {
                molding_shape_name: "",
                wall_cabinet_layer: ""
            },
            searchText: '',
        };
        props.getMcategories();
        props.getMoldingShape();
        props.getWallCabinetLayer();
       
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                molding_shape_id: undefined,
                wall_cabinet_layer_ids : undefined,
                type            : type,
                id              : null,
                thumbnail       : [],
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                guid        : record.guid,
                molding_shape_id: record.molding_shape_id,
                wall_cabinet_layer_ids: JSON.parse(record.wall_cabinet_layer_ids),
                type            : type,
            })
        }
        this.setState({
          visible: open,
        });
    };

    // canBeSubmitted()
    // {
    //     const {name, thumbnail, type} = this.state;
    //     return type==="new" ? (name.length > 0 && thumbnail.length > 0) : (name.length > 0);
    // }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              this.setState({visible: false});
                if(type === "new") {
                    this.props.addMcategories(values);
                }
                if(type === "edit" && id !== null) {
                    this.props.updateMcategories(values, id, this.state.guid);
                }
                
            }
            
          });
    }

    handleDeleteEvent = (record) => {
            const {deleteMcategories} = this.props;
            confirm({
                title: 'Do you want to delete this Item?',
                onOk() {
                    deleteMcategories(record)
                },
                onCancel() {},
            });
    }

    handleMoldingShapeSearch = (value) => {
        let {searchFilter} = this.state;
        searchFilter.molding_shape_name = value;
        this.setState({searchFilter});
    }
    handleWallCabinetShapeSearch = (value) => {
        let {searchFilter} = this.state;
        searchFilter.wall_cabinet_layer = value;
        this.setState({searchFilter});
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {mcategories} = this.props;
        const index = mcategories.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && mcategories[index-1].molding_shape_id === record.molding_shape_id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== mcategories.length - 1 && mcategories[index+1].molding_shape_id === record.molding_shape_id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: mcategories[index].id, sort_order: mcategories[swapIndex].sort_order});
            this.props.setSortOrder({id: mcategories[swapIndex].id, sort_order: mcategories[index].sort_order});
        }
    }

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={'search...'}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <AntDButton
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </AntDButton>
            <AntDButton onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </AntDButton>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => record[dataIndex] && record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text === null ? "" : text.toString()}
          />
        ),
    });

    render()
    {
        const { classes, mcategories, moldingShape, wallCabinetLayer, isLoading} = this.props;
        const { molding_shape_id, wall_cabinet_layer_ids, visible, type, searchFilter} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const { molding_shape_name, wall_cabinet_layer } = searchFilter;

        let typeItems = [];
        let regExp = new RegExp(molding_shape_name, "gim")
        moldingShape.forEach(fElement => {
            if (fElement.png_layer_name.search(regExp) >= 0)
                typeItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.png_layer_name}</Option>);
        })

        regExp = new RegExp(wall_cabinet_layer, "gim")
        const WallCabinetItems = [];
        wallCabinetLayer.forEach(fElement => {
            if (fElement.png_layer_name.search(regExp) >= 0)
                WallCabinetItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.png_layer_name}</Option>);
        })
        const columns = [
            {
                key: 'moldingShape',
                title: 'Molding Shape',
                dataIndex: 'molding_shape_name',
                width: 400,
                ...this.getColumnSearchProps('molding_shape_name'),
            },
            {
                key: 'wall_cabinet_layer_ids',
                title: 'Wall Cabinet Layer',
                dataIndex: 'wall_cabinet_layer_ids',
                width: 400,
                render: (text, record) => (
                    <Row>
                        <Select
                            mode="multiple"
                            disabled={true}
                            value={record.wall_cabinet_layer_ids==="" ? [] : JSON.parse(record.wall_cabinet_layer_ids)}
                            dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                            style={{ width: '100%' }}
                        >
                            {WallCabinetItems}
                        </Select>
                    </Row>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 150,
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Shape"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            molding_shape_id  : this.state.molding_shape_id,
                                            wall_cabinet_layer_ids     : this.state.wall_cabinet_layer_ids,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Molding Categories
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="molding_shape_id"
                                                        {...formItemLayout}
                                                        label="Molding Shape Name"
                                                    >
                                                        {getFieldDecorator("molding_shape_id", {
                                                            initialValue: molding_shape_id,
                                                            validateTrigger: 'onSelect',
                                                            rules: [{ required: true, message: 'Please select your Type Category!' }],
                                                        })(
                                                            (<Select
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select your type category"
                                                                treeDefaultExpandAll
                                                                onSelect={(value) => { this.setState({ molding_shape_id: value}); }}
                                                                showSearch
                                                                filterOption={() => { return true; }}
                                                                onSearch={(value) => this.handleMoldingShapeSearch(value)}
                                                            >
                                                                { typeItems }
                                                            </Select>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="wall_cabinet_layer_ids"
                                                        {...formItemLayout}
                                                        label="Wall Cabinet Layers"
                                                    >
                                                        {getFieldDecorator('wall_cabinet_layer_ids', {
                                                            initialValue: wall_cabinet_layer_ids,
                                                            rules: [ {
                                                                required: true, message: 'Please Cabinet Door Color.',
                                                            }],
                                                        })(
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: '100%' }}
                                                                dropdownStyle={{zIndex: 10000, maxHeight: 400, overflow: 'auto' }} 
                                                                showSearch
                                                                filterOption={() => { return true; }}
                                                                onSearch={(value) => this.handleWallCabinetShapeSearch(value)}
                                                            >
                                                                {WallCabinetItems}
                                                            </Select>,
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={mcategories}
                                    scroll={{x: 1400, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getMcategories: Actions.getMcategories,
        getMoldingShape: Actions.getMoldingShape,
        getWallCabinetLayer: Actions.getWallCabinetLayer,
        addMcategories: Actions.addMcategories,
        deleteMcategories: Actions.deleteMcategories,
        updateMcategories: Actions.updateMcategories,
        showMessage     : appActions.showMessage,
        setSortOrder: Actions.setSortOrder,

    }, dispatch);
}

function mapStateToProps({mcategories, fuse})
{
    return {
        mcategories: mcategories.mcategories.mcategories,
        moldingShape: mcategories.mcategories.moldingShape,
        wallCabinetLayer: mcategories.mcategories.wallCabinetLayer,
        isLoading:fuse.loading.isLoading
    }
}
const McategoriesComponent = Form.create()(Mcategories);

export default withReducer('mcategories', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(McategoriesComponent))));