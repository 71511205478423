import React, {Component} from 'react';
import * as Actions from './store/actions';
import * as appActions from 'app/store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider, Chip} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import {Table, Typography, Input, Upload, Form, Spin, Modal, Row, Col, Tooltip, TreeSelect, Select, Checkbox, Icon, Button as AntDButton} from 'antd';
import TransitionList from '../categories/transition-list';
import Highlighter from 'react-highlight-words';

var ColorPicker = require('rc-color-picker');
const { Option } = Select;
const { Title } = Typography;
const { TreeNode } = TreeSelect;
const FormItem = Form.Item;
const {confirm} = Modal;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70', '100', '200', '500'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 650,
    },
});

class MoldingShape extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            visible     : false,
            type        : "new",
            id          : null,
            fieldData   : {
                png_layer_name   : '',
                texture_name :'',
                main_background_id   : undefined,
                png_layer_url       : [],
                texture_url         : [],
                cabinet_type_name   : 'None',
                selected            : false,
                transitions: []
            },
            searchText: '',
        };
        this.props.getConstData();        
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                id          : null,
                fieldData   : {
                    png_layer_name   : '',
                    texture_name :'',
                    main_background_id   : undefined,
                    png_layer_url       : [],
                    texture_url         : [],
                    cabinet_type_name             : '',
                    selected            : false,
                    transitions: []
            },
            })
        }
        else if(type === "edit") {
            this.setState({
                id          : record.id,
                guid          : record.guid,
                fieldData   : {
                    png_layer_name       : record.png_layer_name,
                    texture_name : record.texture_name,
                    main_background_id   : record.main_background_id,
                    selected            : record.selected === 1 ? true : false,
                    png_layer_url       : [{
                        uid : 1,
                        name: record.png_layer_url.split('/').pop(),
                        status: 'done',
                        url: record.png_layer_url,
                    }],
                    texture_url         : [{
                        uid : 1,
                        name: record.texture_url.split('/').pop(),
                        status: 'done',
                        url: record.texture_url,
                    }],
                    cabinet_type_name : record.cabinet_type_name,
                    transitions: JSON.parse(record.transitions)
                },
            })
        }
        this.setState({
            type    : type,
            visible : open,
        });
    };

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            let transitions = this.state.fieldData.transitions;

            // Remove invaild transtions
            for (let i = transitions.length - 1; i >= 0; i--) {
                if (transitions[i].mainbg === '' || transitions[i].layer === '') {
                    transitions.splice(i, 1);
                }
            }

            values.transitions = JSON.stringify(transitions);

            if(!err) {
                this.setState({visible: false});
                const {type, id} = this.state;
                if(type === "new") {
                    this.props.addMoldingShape(values);
                }
                if(type === "edit" && id !== null) {
                    this.props.updateMoldingShape(values, id, this.state.guid);
                }
            }
        });
    }

    handleDeleteEvent = (record) => {
        const {deleteMoldingShape} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteMoldingShape(record)
            },
            onCancel() {},
        });
    }

    handleClose = () => {
        this.setState({ open: false });
      };

    normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    moveAction = (type, record) => () => {
        const {moldingshape} = this.props;
        const index = moldingshape.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && moldingshape[index-1].main_background_id === record.main_background_id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== moldingshape.length - 1 && moldingshape[index+1].main_background_id === record.main_background_id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: moldingshape[index].id, sort_order: moldingshape[swapIndex].sort_order});
            this.props.setSortOrder({id: moldingshape[swapIndex].id, sort_order: moldingshape[index].sort_order});
        }
    }

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={'search...'}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <AntDButton
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </AntDButton>
            <AntDButton onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </AntDButton>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => record[dataIndex] && record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text === null ? "" : text.toString()}
          />
        ),
    });

    render()
    {
        const { classes, mainbackgroundData, moldingshape, isLoading} = this.props;
        const { fieldData, visible, type} = this.state;
        const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;

        const cabinetData = [
            {id:1,name:'Crown Moldings'},
        ];
        const typeItems = [];
        cabinetData.forEach(fElement => {
            typeItems.push(<Option key={fElement.id} value={fElement.name}>{fElement.name}</Option>);
        })

        const menuItems = [];
        mainbackgroundData.forEach(fElement => {
            menuItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.main_background_name}</Option>);
        })
    
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );

        const backgroundItems = [];
        mainbackgroundData.forEach(fElement => {
            backgroundItems.push({
                text: fElement.main_background_name,
                value: fElement.main_background_name
            });
        });
        backgroundItems.push({
            text: 'null',
            value: ' '
        })

        const columns = [
            {
                key: 'main_background_name',
                title: 'Main Background Name',
                dataIndex: 'main_background_name',
                width: 150,
                filters: backgroundItems,
                onFilter: (value, record) => record.main_background_name == value,
            },
            {
                key: 'cabinet_type_name',
                title: 'Category',
                dataIndex: 'cabinet_type_name',
                width: 200,
                onFilter: (value, record) => record.cabinet_type_name == value,
            },
            {
                key: 'png_layer_name',
                title: 'Layer Name',
                dataIndex: 'png_layer_name',
                width: 200,
                ...this.getColumnSearchProps('png_layer_name'),
            },
            {
                key: 'png_layer_url',
                title: 'Layer Image',
                dataIndex: 'png_layer_url',
                colSpan: 2,
                width: 400,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text.slice(0,40) + '...'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'layerThumbnailPreview',
                dataIndex: 'png_layer_url',
                colSpan: 0,
                width: 150,
                render: (text,record) => (
                    <Row className="list__thumbnail">
                       <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'texture_name',
                title: 'Icon Name',
                dataIndex: 'texture_name',
                width: 200,
            },
            {
                key: 'texture_url',
                title: 'Menu Icon',
                dataIndex: 'texture_url',
                colSpan: 2,
                width: 400,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text.slice(0,40) + '...'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'textureThumbnailPreview',
                dataIndex: 'texture_url',
                colSpan: 0,
                width: 150,
                render: (text,record) => (
                    <Row className="list__thumbnail">
                       <img src={text} alt="" />
                    </Row>
                ),
            },
      
            {
                key: 'selected',
                title: 'Selected',
                dataIndex: 'selected',
                width: 200,
                render: (text, record) => (
                    <Chip
                        label={record.selected === 1 ? "true" : "false"}
                        className={classes.button}
                        color={record.selected === 1 ? "secondary" : "primary"}
                    />
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        {/* <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton> */}
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        // For Transition List
        let rooms360 = mainbackgroundData.filter(item => item.room_flag && item.id !== fieldData.main_background_id);

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Molding Shape"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {setFieldsValue({...this.state.fieldData})}}
                                    size="large"
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} MoldingShape
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="main_background_id"
                                                        {...formItemLayout}
                                                        label="Main Background"
                                                    >
                                                        {getFieldDecorator('main_background_id', {
                                                            initialValue: fieldData.main_background_id,
                                                            rules: [{ required: true, message: 'Please select your background img!' }],
                                                        })(
                                                            <Select 
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }} placeholder="Please select your background img"
                                                                onChange={ (value) => {this.setState({fieldData: {...fieldData, main_background_id: value}})} }
                                                            >
                                                                {menuItems}
                                                            </Select>,
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="cabinet_type_name"
                                                        {...formItemLayout}
                                                        label="Category"
                                                    >
                                                        {getFieldDecorator('cabinet_type_name', {
                                                            initialValue: fieldData.cabinet_type_name,
                                                            rules: [{ required: true, message: 'Please select your cabinet type!' }],
                                                        })(
                                                            <Select
                                                                dropdownStyle={{zIndex: 10000, maxHeight: 400, overflow: 'auto' }} 
                                                                placeholder="Please select your cabinet type"
                                                            >
                                                                <Option key='None' value='None'>None</Option>
                                                                {typeItems}
                                                            </Select>,
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="png_layer_name"
                                                        {...formItemLayout}
                                                        label="Layer Name"
                                                    >
                                                        {getFieldDecorator("png_layer_name", {
                                                            initialValue: fieldData.png_layer_name,
                                                            rules: [{ required: true, message: 'Enter Layer Name!' }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Layer Name"
                                                                onChange={(e)=> {
                                                                    this.setState({fieldData : {...fieldData, png_layer_name : e.target.value}});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="texture_name"
                                                        {...formItemLayout}
                                                        label="Icon Name"
                                                    >
                                                        {getFieldDecorator("texture_name", {
                                                            initialValue: fieldData.texture_name,
                                                            rules: [{ required: true, message: 'Enter Texture Name!' }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Texture Name"
                                                                onChange={(e)=> {
                                                                    this.setState({fieldData : {...fieldData, texture_name : e.target.value}});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="texture_url"
                                                        {...formItemLayout}
                                                        label="Menu Icon"
                                                    >
                                                        {getFieldDecorator("texture_url", {
                                                            initialValue        : fieldData.texture_url,
                                                            valuePropName       : 'fileList',
                                                            getValueFromEvent   : this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({fieldData: {...fieldData, texture_url: []}})
                                                                }}
                                                                beforeUpload={file => {
                                                                    this.setState({fieldData: {...fieldData, texture_url: [file]}})
                                                                    return false;
                                                                }}
                                                                >
                                                                { getFieldValue("texture_url").length>0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="png_layer_url"
                                                        {...formItemLayout}
                                                        label="Layer Image"
                                                    >
                                                        {getFieldDecorator("png_layer_url", {
                                                            initialValue        : fieldData.png_layer_url,
                                                            valuePropName       : 'fileList',
                                                            rules: [{ required: true, message: 'Please upload Thumbnail!' }],
                                                            getValueFromEvent   : this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({fieldData: {...fieldData, png_layer_url: []}})
                                                                }}
                                                                beforeUpload={file => {
                                                                    this.setState({fieldData: {...fieldData, png_layer_url: [file]}})
                                                                    return false;
                                                                }}
                                                                >
                                                                { getFieldValue("png_layer_url").length>0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="selected"
                                                        {...formItemLayout}
                                                        label="Default Style"
                                                    >
                                                        {getFieldDecorator('selected', {
                                                        valuePropName: 'checked',
                                                        initialValue: fieldData.selected,
                                                        })(<Checkbox></Checkbox>)}
                                                    </FormItem>
                                                </Col>            
                                            </Row>
                                            <Row>
                                                <Col
                                                    offset={2}
                                                    span={22}
                                                    style={{
                                                        color: 'rgba(0,0,0,0.8)',
                                                        fontSize: 15,
                                                        paddingBottom: 16
                                                    }}
                                                >
                                                    Transitions:
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col offset={2} span={22}>
                                                    {getFieldDecorator("transitions", {
                                                        initialValue: fieldData.transitions,
                                                    })(
                                                        <div>
                                                            <TransitionList
                                                                layerList={moldingshape}
                                                                listName='transitions'
                                                                mainBackgroundList={rooms360}
                                                                onChange={list => this.setState({ fieldData: { ...fieldData, transitions: list } })}
                                                                transitions={fieldData.transitions}
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div
                                            style={{
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={moldingshape}
                                    scroll={{x: 2300, y:500}}
                                />   
                            </Row>
                        </div>    
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getConstData    : Actions.getConstData,
        addMoldingShape    : Actions.addMoldingShape,
        deleteMoldingShape : Actions.deleteMoldingShape,
        updateMoldingShape : Actions.updateMoldingShape,
        showMessage     : appActions.showMessage,
        setSortOrder: Actions.setSortOrder,
    }, dispatch);
}

function mapStateToProps({moldingshapeApp, fuse})
{
    return {
        moldingshape: moldingshapeApp.moldingshape.moldingshape,
        mainbackgroundData: moldingshapeApp.moldingshape.mainbackgroundData,

        isLoading:fuse.loading.isLoading
    }
}
const MoldingShapeComponent = Form.create()(MoldingShape);


export default withReducer('moldingshapeApp', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(MoldingShapeComponent))));