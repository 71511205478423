import history from '@history';
import {setInitialSettings} from 'app/store/actions/fuse';
// import {setDefaultSettings, setInitialSettings} from 'app/store/actions/fuse';
import _ from '@lodash';
import store from 'app/store';
import * as Actions from 'app/store/actions';

import jwtServiceDealer from 'app/services/jwtService/jwtServiceDealer';

export const SET_DEALER_DATA = '[DEALER] SET DATA';
export const REMOVE_DEALER_DATA = '[DEALER] REMOVE DATA';
export const DEALER_LOGGED_OUT = '[DEALER] LOGGED OUT';


/**
 * Set Dealer Data
 */
export function setDealerData(dealer)
{
    return (dispatch) => {
        /*
        Set Dealer Settings
         */
        /*
        Set Dealer Data
         */
        dispatch({
            type   : SET_DEALER_DATA,
            payload: dealer
        })
    }
}

/**
 * Update Dealer Settings
 */
export function updateDealerSettings(settings)
{
    return (dispatch, getState) => {
        const oldDealer = getState().auth.dealer;
        const dealer = _.merge({}, oldDealer, {data: {settings}});

        updateDealerData(dealer);

        return dispatch(setDealerData(dealer));
    }
}

/**
 * Update Dealer Shortcuts
 */
export function updateDealerShortcuts(shortcuts)
{
    return (dispatch, getState) => {
        const dealer = getState().auth.dealer;
        const newDealer = {
            ...dealer,
            data: {
                ...dealer.data,
                shortcuts
            }
        };

        updateDealerData(newDealer);

        return dispatch(setDealerData(newDealer));
    }
}

/**
 * Remove Dealer Data
 */
export function removeDealerData()
{
    return {
        type: REMOVE_DEALER_DATA
    }
}

/**
 * Logout
 */
export function logoutDealer()
{

    return (dispatch, getState) => {

        const dealer = getState().auth.dealer;

        // if ( !dealer.role || dealer.role.length === 0 )// is guest
        // {
        //     return null;
        // }

        history.push({
            pathname: '/admin/login'
        });

        switch ( dealer.from )
        {
            default:
            {
                jwtServiceDealer.logout();
            }
        }

        dispatch(setInitialSettings());

        dispatch({
            type: DEALER_LOGGED_OUT
        })
    }
}
/**
 * Update Dealer Data
 */
function updateDealerData(dealer)
{
    if ( !dealer.role || dealer.role.length === 0 )// is guest
    {
        return;
    }

    switch ( dealer.from )
    {
        default:
        {
            jwtServiceDealer.updateDealerData(dealer)
                .then(() => {
                    store.dispatch(Actions.showMessage({message: "Dealer data saved with api"}));
                })
                .catch(error => {
                    store.dispatch(Actions.showMessage({message: error.message}));
                });
            break;
        }
    }
}
