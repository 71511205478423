import * as Actions from '../actions';

const initialState = {
    success: false,
    error  : {
        username: null,
        password: null
    },
    successDealer: false,
    resetDealer  : false,
    errorDealer  : {
        username: null,
        password: null
    },
};

const login = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.LOGIN_SUCCESS:
        {
            return {
                ...state,
                success: true
            };
        }
        case Actions.LOGIN_ERROR:
        {
            return {
                ...state,
                error  : action.payload,
                success: false,
            };
        }
        case Actions.LOGOUT:
        {
            return {
                ...initialState,
            }
        }
        
        case Actions.RESET_SUCCESS_DEALER:
        {
            return {
                ...state,
                resetDealer: true,
            };
        }
        case Actions.LOGIN_SUCCESS_DEALER:
        {
            return {
                ...state,
                successDealer: true
            };
        }
        case Actions.LOGIN_ERROR_DEALER:
        {
            return {
                ...state,
                successDealer: false,
                errorDealer  : action.payload
            };
        }
        case Actions.LOGOUT_DEALER:
        {
            return {
                ...initialState
            }
        }
        default:
        {
            return state
        }
    }
};

export default login;